<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import Chart from "chart.js/auto";
import Swal from "sweetalert2";

export default {
    components: {
        Header,
        SideBar
    },
    data() {
        return {
            paylinks: [],
            transactions: [],
            userData: {
                nom: '',
                adresse: '',
                telephone: '',
                numero_registre_commerce: ''
            },
            clientId: '',
            balance: null,
            retrait: null,
            depots: null,
            operatorTotals: [],
            paylinksStats: {
                approved: 0,
                pending: 0,
                rejected: 0,
                expired: 0
            },
            totaux_depots: {
                MTN: {
                    montant: 0,
                    count: 0
                },
                MOOV: {
                    montant: 0,
                    count: 0
                },
                CELTIS: {
                    montant: 0,
                    count: 0
                }
            },
            totaux_payouts: {
                MTN: {
                    montant: 0,
                    count: 0
                },
                MOOV: {
                    montant: 0,
                    count: 0
                },
                CELTIS: {
                    montant: 0,
                    count: 0
                }
            },
        };
    },
    methods: {
        async fetchBalance() {
            try {
                const response = await axios.get(`/client-balance/${this.clientId}`);
                this.balance = Math.floor(response.data.balance);
                this.depots = Math.floor(response.data.depots);
                this.retrait = Math.floor(response.data.retrait);
            } catch (error) {
                console.error(error);
            }
        },

        async fetchData() {
            try {
                const response = await axios.get(`/total-amount-by-operateur/${this.clientId}`);
                this.totaux_depots = response.data.totaux_depots;
                console.log(response)
                this.totaux_payouts = response.data.totaux_payouts;

            } catch (error) {
                console.error(error);
            }
        },

        async fetchOperatorTotals() {
            try {
                const response = await axios.get(`/client-operator-sum/${this.clientId}`);
                this.operatorTotals = response.data.totals;
                this.renderPieChart();
            } catch (error) {
                console.error('Erreur lors de la récupération des totaux par opérateur:', error);
            }
        },

        async fetchPaylinksStats() {
            try {
                const response = await axios.get(`/paylinks-by-status/${this.clientId}`);
                this.paylinksStats.approved = response.data.approved;
                this.paylinksStats.pending = response.data.pending;
                this.paylinksStats.rejected = response.data.rejected;
                this.paylinksStats.expired = response.data.expired;
            } catch (error) {
                console.error('Erreur lors de la récupération des statistiques des paylinks :', error);
            }
        },
        async loadTransactions(page = 1) {
            this.loading = true;
            try {
                if (this.clientId) {
                    const params = {
                        page,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        operateur: this.selectedOperateur,
                        statut: this.selectedStatut,
                    };

                    const response = await axios.get(`/transactions/client/${this.clientId}`, {
                        params
                    });
                    this.transactions = response.data.transactions.data;
                    this.currentPage = response.data.transactions.current_page;
                    this.totalPages = response.data.transactions.last_page;
                } else {
                    Swal.fire("Erreur", "Client ID introuvable", "error");
                }
            } catch (error) {
                console.error("Erreur lors du chargement des transactions:", error);
            } finally {
                this.loading = false;
            }
        },

        fetchPaylinks(page = 1) {
            const clientId = this.getClientIdFromSession();
            if (clientId) {
                axios
                    .get(`/paylinks/${clientId}`, {
                        params: {
                            page: page,
                            startDate: this.startDate,
                            endDate: this.endDate,
                            status: this.status,
                            mode: this.mode,
                            objet: this.objet
                        },
                    })
                    .then((response) => {
                        this.paylinks = response.data.data;
                        this.currentPage = response.data.current_page;
                        this.totalPages = response.data.last_page;
                    })
                    .catch((error) => {
                        console.error(
                            "Erreur lors de la récupération des paylinks:",
                            error
                        );
                    });
            }
        },

        getClientIdFromSession() {
            const userDataString = sessionStorage.getItem("userData");
            if (userDataString) {
                try {
                    const userData = JSON.parse(userDataString);
                    return userData.additional_info.id;
                } catch (error) {
                    console.error(
                        "Erreur lors du parsing de userData depuis sessionStorage:",
                        error
                    );
                }
            }
            return null;
        },

        goToPage(page) {
            if (page > 0 && page <= this.totalPages) {
                this.loadTransactions(page);
            }
        },

        renderPieChart() {
            const ctx = document.getElementById('pie-chart').getContext('2d');
            const labels = this.operatorTotals.map(item => item.operateur);
            const data = this.operatorTotals.map(item => item.total_amount);

            new Chart(ctx, {
                type: 'pie',
                data: {
                    labels: labels,
                    datasets: [{
                        label: 'Total des transactions par opérateur',
                        data: data,
                        backgroundColor: ['#FFD014', '#36A2EB', '#FFCE56', '#FF5733', '#C70039'],
                        borderColor: '#fff',
                        borderWidth: 1,
                    }],
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    let label = context.label || '';
                                    if (label) {
                                        label += ': ';
                                    }
                                    if (context.parsed !== null) {
                                        label += `${context.parsed} FCFA`;
                                    }
                                    return label;
                                }
                            }
                        }
                    },
                },
            });
        },
    },
    async mounted() {
        const userDataString = sessionStorage.getItem('userData');
        if (userDataString) {
            try {
                const parsedUserData = JSON.parse(userDataString);
                this.clientId = parsedUserData.additional_info.id;
                this.userData.nom = parsedUserData.additional_info.nom;
                this.userData.created_at = parsedUserData.additional_info.created_at;
                this.userData.adresse = parsedUserData.additional_info.adresse;
                this.userData.telephone = parsedUserData.additional_info.telephone;
                this.userData.numero_registre_commerce = parsedUserData.additional_info.numero_registre_commerce;
                // Appels d'API séquentiels
                await this.fetchBalance();
                await this.fetchOperatorTotals();
                await this.fetchPaylinksStats();
                await this.fetchData();
                await this.loadTransactions();
                await this.fetchPaylinks();
            } catch (error) {
                console.error(error);
            }
        }
    },
};
</script>

<template>
<Header />
<div class="container-fluid">
    <div class="row">
        <SideBar />
        <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
            <div class="row my-4 d-flex align-items-stretch">
                <!-- Balance and Transactions -->
                <div class="col-lg-7 col-12">
                    <div class="row my-2">
                        <div class="col-6">
                            <div class="custom-block custom-block-paylinks bg-white p-4 h-100">
                                <h5 class="mb-3 text-start">PAYLINKS</h5>
                                <div class="row text-start">
                                    <!-- Paylinks IN PROGRESS -->
                                    <div class="col-lg-8 col-md-2 col-4">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000"> EN ENTENTES </strong>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-2 col-4">
                                        <div class="badge-paylink">
                                            : {{ paylinksStats.pending }}
                                        </div>
                                    </div>
                                    <!-- Valid Paylinks -->
                                    <div class="col-lg-8 col-md-2 col-4">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000"> VALIDES</strong>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-2 col-4">
                                        <div class="badge-paylink">
                                            : {{ paylinksStats.approved }}
                                        </div>
                                    </div>

                                    <!-- Expired Paylinks -->
                                    <div class="col-lg-8 col-md-2 col-4">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000"> EXPIRES</strong>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-2 col-4">
                                        <div class="badge-paylink">
                                            : {{ paylinksStats.expired }}
                                        </div>
                                    </div>

                                    <div class="col-lg-8 col-md-2 col-4">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000"> REJETES</strong>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-2 col-4">
                                        <div class="badge-paylink">
                                            : {{ paylinksStats.rejected }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="custom-block custom-block-paylinks bg-white p-4 h-100">
                                <h5 class="mb-3 text-start">DEPOTS</h5>
                                <div class="row text-start">
                                    <div class="col-lg-8 col-md-2 col-4">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000">MTN</strong>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-2 col-4">
                                        <div class="badge-paylink">
                                            : {{ totaux_depots.MTN.montant }}
                                        </div>
                                    </div>

                                    <div class="col-lg-8 col-md-2 col-4">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000">MOOV</strong>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-2 col-4">
                                        <div class="badge-paylink">
                                            : {{ totaux_depots.MOOV.montant }}
                                        </div>
                                    </div>

                                    <div class="col-lg-8 col-md-2 col-4">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000">CELTIS</strong>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-2 col-4">
                                        <div class="badge-paylink">
                                            : {{ totaux_depots.CELTIS.montant }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6 mt-3">
                            <div class="custom-block custom-block-paylinks bg-white p-4 h-100">
                                <h5 class="mb-3 text-start">RETRAIT</h5>
                                <div class="row text-start">
                                    <div class="col-lg-8 col-md-2 col-4">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000">MTN</strong>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-2 col-4">
                                        <div class="badge-paylink">
                                            : {{ totaux_payouts.MTN.montant }}
                                        </div>
                                    </div>

                                    <div class="col-lg-8 col-md-2 col-4">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000">MOOV</strong>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-2 col-4">
                                        <div class="badge-paylink">
                                            : {{ totaux_payouts.MOOV.montant }}
                                        </div>
                                    </div>

                                    <div class="col-lg-8 col-md-2 col-4">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000">CELTIS</strong>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-2 col-4">
                                        <div class="badge-paylink">
                                            : {{ totaux_payouts.CELTIS.montant }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6 mt-3">
                            <div class="custom-block custom-block-paylinks bg-white p-4 h-100">
                                <h5 class="mb-3 text-start">TRANSACTIONS</h5>
                                <div class="row text-start">
                                    <div class="col-lg-8 col-md-2 col-4">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000">MTN</strong>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-2 col-4">
                                        <div class="badge-paylink">
                                            : {{ totaux_depots.MTN.count }}
                                        </div>
                                    </div>

                                    <div class="col-lg-8 col-md-2 col-4">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000">MOOV</strong>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-2 col-4">
                                        <div class="badge-paylink">
                                            : {{ totaux_depots.MOOV.count }}
                                        </div>
                                    </div>

                                    <div class="col-lg-8 col-md-2 col-4">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000">CELTIS</strong>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-2 col-4">
                                        <div class="badge-paylink">
                                            : {{ totaux_depots.CELTIS.count }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="custom-block bg-white">
                        <h5 class="mb-3 text-start">DERNIERS ENGAGEMENTS</h5>
                        <div class="table-responsive">
                            <table class="account-table table">
                                <thead style="background-color: #006666">
                                    <tr>
                                        <th scope="col" style="color: white">N°</th>
                                        <th scope="col" style="color: white">DATE</th>
                                        <th scope="col" style="color: white">EXPÉDITEUR</th>
                                        <th scope="col" style="color: white">MONTANT</th>
                                        <th scope="col" style="color: white">GSM</th>
                                        <th scope="col" style="color: white">STATUT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- Afficher le spinner pendant le chargement -->
                                    <tr v-if="loading">
                                        <td colspan="6" class="text-center">
                                            <div class="spinner-border text-success" role="status">
                                                <span class="sr-only">Chargement en cours...</span>
                                            </div>
                                            <p>Chargement en cours...</p>
                                        </td>
                                    </tr>

                                    <!-- Afficher un message si aucune donnée n'est disponible -->
                                    <tr v-if="!loading && transactions.length === 0">
                                        <td colspan="6" class="text-center">
                                            <div class="alert alert-warning d-flex justify-content-center align-items-center" role="alert">
                                                Aucune donnée disponible.
                                            </div>
                                        </td>
                                    </tr>

                                    <!-- Affichage des transactions lorsqu'elles sont disponibles -->
                                    <tr v-for="(transaction, index) in transactions" :key="transaction.id">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ new Date(transaction.created_at).toLocaleDateString() }}</td>
                                        <td>{{ transaction.nomdestinataire }}</td>
                                        <td>{{ transaction.montant }}</td>

                                        <td>
                                            <img v-if="transaction.operateur === 'MTN'" src="/../../images/MOMOPAY.png" alt="MTN" style="width: 35px; height: 34px" />
                                            <img v-else-if="transaction.operateur === 'MOOV'" src="/../../images/MOOVFLOOZ.png" alt="MOOV" style="width: 35px; height: 34px" />
                                            <img v-else-if="transaction.operateur === 'CELTIIS'" src="/../../images/LOGOCELTIS.png" alt="CELTIIS" style="width: 35px; height: 34px" />
                                            <span v-else>{{ transaction.operateur }}</span>
                                        </td>
                                        <td>
                                            <span v-if="transaction.status === 'PENDING'" class="badge text-bg-warning">
                                                {{ transaction.status }}
                                            </span>
                                            <span v-else-if="transaction.status === 'SUCCESSFUL'" class="badge text-bg-success">
                                                {{ transaction.status }}
                                            </span>
                                            <span v-else-if="transaction.status === 'FAILED'" class="badge text-bg-danger">
                                                {{ transaction.status }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- Phrase avec l'icône pour voir toutes les transactions -->
                        <div class="text-end mt-3">
                            <a href="/client/mes-transactions" class="btn btn-link">
                                Voir toutes les transactions
                                <i class="fas fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>

                </div>

                <div class="col-lg-5 col-6">
                    <div class="custom-block custom-block-profile-front custom-block-profile text-center bg-white h-35">
                        <div class="d-flex justify-content-between align-items-start">
                            <div class="text-start w-50">
                                <h6 class="mb-0">TRANSACTIONS / GSM</h6>
                                <canvas id="pie-chart"></canvas>
                            </div>
                            <!-- Transaction Details -->
                            <div class="text-start ms-1">
                                <div class="d-flex flex-column">
                                    <h6 class="text-center mb-0">DEBIT</h6>
                                    <a class="ms-1"><b>{{ retrait }}</b></a>
                                    <h6 class="text-center mb-0 mt-1">CREDIT</h6>
                                    <a class="ms-1"><b>{{ depots }}</b></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row my-4">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">PAYLINKS EN ATTENTES DE VALIDATION</h5>
                                <div class="table-responsive">
                                    <table class="account-table table texte-sm">
                                        <thead style="background-color: #006666">
                                            <tr>
                                                <th scope="col" style="color: white">N°</th>
                                                <th scope="col" style="color: white" class="d-none d-md-table-cell">CREER LE</th>
                                                <th scope="col" style="color: white">SOLDE</th>
                                                <th scope="col" style="color: white" class="d-none d-md-table-cell">OBJET</th>
                                                <th scope="col" style="color: white">MODE</th>
                                                <th scope="col" style="color: white">STATUT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(paylink, index) in paylinks" :key="paylink.id" @click="selectedPaylink = paylink.id">
                                                <td>{{ (currentPage - 1) * 15 + index + 1 }}</td>
                                                <td>{{ new Date(paylink.created_at).toLocaleDateString() }}</td>
                                                <td>{{ paylink.solde }} FCFA</td>
                                                <td>{{ paylink.objet }}</td>
                                                <td>{{ paylink.mode }}</td>
                                                <td>
                                                    <span v-if="paylink.status === 'pending'" class="badge rounded-pill text-bg-warning">En attente</span>
                                                    <span v-if="paylink.status === 'approved'" class="badge rounded-pill text-bg-success">Approuvé</span>
                                                    <span v-if="paylink.status === 'rejected'" class="badge rounded-pill text-bg-danger">Rejeté</span>
                                                    <span v-if="paylink.status === 'expired'" class="badge rounded-pill text-bg-secondary">Expiré</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="text-end mt-3">
                                    <a href="/service-client/all-paylinks" class="btn btn-link">
                                        Voir tout les paylinks
                                        <i class="fas fa-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
</template>

<style scoped>
.account-table {
    border-collapse: collapse;
    width: 100%;
}
</style>
