<template>
    <nav id="sidebarMenu" class="col-md-2 col-lg-2 d-md-block sidebar collapse">
        <div class="position-sticky py-4 px-3 sidebar-sticky">
            <ul class="nav flex-column h-100">
                <li class="nav-item" v-for="item in menuItems" :key="item.title">
                    <router-link v-if="userRole === item.role" class="nav-link" :to="item.link" @click.prevent="item.action ? item.action() : null">
                        <i :class="item.iconClass + ' me-2'"></i>
                        <b>{{ item.title }}</b>
                    </router-link><br v-if="item.br">
                </li>
            </ul>
        </div>
    </nav>
</template>


<script>
import axios from "@/axs/axios.js";
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            userRole: null,
            menuItems: []
        };
    },
    mounted() {
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
            try {
                const parsedUserData = JSON.parse(userDataString);
                this.userData = parsedUserData.additional_info;
                this.getUserRole();
            } catch (error) {
                console.error("Erreur lors du parsing de userData depuis sessionStorage:", error);
            }
        }
    },

    methods: {
        confirmLogout() {
            Swal.fire({
                title: 'Êtes-vous sûr de vouloir vous déconnecter ?',
                icon: 'warning',
                cancelButtonText: 'Annuler',
                cancelButtonColor: "#d33",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: 'Oui',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.logoutUser();
                }
            });
        },

        logoutUser() {
            sessionStorage.clear();
            window.location.href = "/";
        },

        getUserRole() {
            const sessionData = JSON.parse(sessionStorage.getItem('userData'));
            if (sessionData && sessionData.role_id) {
                switch (sessionData.role_id) {
                    case '64d28e8f-816f-4804-afe8-b77594cbe5db':
                        this.userRole = 'admin';
                        this.setMenuItemsForAdmin();
                        break;
                    case 'b1a00df2-3f9e-4875-9d39-643f11609fd3':
                        this.userRole = 'client';
                        this.setMenuItemsForClient();
                        break;
                    case '82e94d28-22b1-4abf-85e3-4a9b461d286f':
                        this.userRole = 'service-client';
                        this.setMenuItemsForServiceClient();
                        break;
                    default:
                        this.userRole = null;
                }
            }
        },

        setMenuItemsForAdmin() {
            this.menuItems = [
                { title: 'ACCUEIL', link: '/admin/admin', iconClass: 'bi-house-fill', role: 'admin', br: true },
                { title: 'ENTITES', link: '/admin/mes-entites', iconClass: 'bi-people-fill', role: 'admin' },
                { title: 'CONFIGURATION', link: '/admin/mes-service-clients', iconClass: 'bi-gear-fill', role: 'admin', br: true },
                { title: 'STATISTIQUES', link: '/admin/stat-payouts-deposits', iconClass: 'bi-cash-stack', role: 'admin', br: true },
                { title: 'DECONNEXION', link: '#', iconClass: 'bi-box-arrow-left', role: 'admin', br: false, action: this.confirmLogout }
            ];
        },

        setMenuItemsForClient() {
            this.menuItems = [
                { title: 'ACCUEIL', link: '/client/dashboard', iconClass: 'bi-house-door-fill', role: 'client', br: true },
                { title: 'PAYLINKS', link: '/client/mes-paylinks', iconClass: 'bi-link-45deg', role: 'client' },
                { title: 'DEPOTS', link: '/client/mes-transactions', iconClass: 'bi-cash-stack', role: 'client' },
                { title: 'RETRAITS', link: '/client/mes-retraits', iconClass: 'bi-arrow-down-circle-fill', role: 'client', br: true },
                { title: 'CONTACT', link: '/client/mes-contacts', iconClass: 'bi-person-lines-fill', role: 'client', br: true },
                { title: 'TRANSACTIONS', link: '/client/rapports', iconClass: 'bi-wallet', role: 'client', br: true },
                { title: 'TUTORIELS', link: '/client/tutorial', iconClass: 'bi-journal-bookmark-fill', role: 'client', br: true },
                { title: 'DECONNEXION', link: '#', iconClass: 'bi-box-arrow-left', role: 'client', br: false, action: this.confirmLogout }
            ];
        },

        setMenuItemsForServiceClient() {
            this.menuItems = [
                { title: 'REMBOURSSEMENTS', link: '/service-client/mon-espace', iconClass: 'bi-arrow-return-left', role: 'service-client', br: true },
                { title: 'E-ENGAGEMENTS', link: '/service-client/alert-engagement', iconClass: 'bi-check-circle', role: 'service-client' },
                { title: 'TUTORIELS', link: '/service-client/ajouter-tutorial', iconClass: 'bi-journal-bookmark', role: 'service-client', br: true },
                { title: 'DECONNEXION', link: '#', iconClass: 'bi-box-arrow-left', role: 'service-client', br: false, action: this.confirmLogout }
            ];
        }
    }
}
</script>

<style>
#sidebarMenu .nav-link.active,
#sidebarMenu .nav-link:hover {
    color: #fff;
}
</style>