<template>
  <div>
  
      <Head />
      <br /><br /><br />
  
      <div class="col-lg-12 col-12 text-center">
          <div v-if="paylink" class="d-flex flex-column align-items-center justify-content-center">
              <!-- Vérifier si le paylink est expiré -->
              <div v-if="new Date() >= new Date(paylink.expiration_at)" class="alert alert-danger mt-3">
                  Ce paylink est expiré.
                  <img src="/images/unavailable_link.svg" alt="Logo du paylink" class="img-fluid paylink-image" />
              </div>
  
              <!-- Afficher l'image et le bouton si le paylink n'est pas désactivé et pas expiré -->
              <div v-else-if="paylink.status !== 'desactived'" class="image-container mb-3">
                  <img :src="BACK_URL + paylink.image" alt="Logo du paylink" class="img-fluid paylink-image" />
              </div>
  
              <div v-else class="alert alert-warning mt-3">
                  Ce lien de paiement est désactivé et n'est plus disponible.
                  <img src="/images/unavailable_link.svg" alt="Logo du paylink" class="img-fluid paylink-image" />
              </div>
  
              <!-- Carte de description du Paylink -->
              <div class="card" style="max-width: 1000px; margin: 20px auto; border-radius: 10px; background-color: #F1FAEE; padding: 20px;">
                  <div class="card-body text-start">
                      <!-- Ajout de text-start ici -->
  
                      <div class="d-flex justify-content-center align-items-center">
                          <div v-if="paylink && paylink.client">
                              <h4 class="mt-2 mb-3">
                                  {{ paylink.title }}
                              </h4>
                          </div>
                          <div v-else>
                              <div class="alert alert-warning mt-3">
                                  Client non disponible
                              </div>
                          </div>
                      </div>
                      <hr class="title-hr" />
                      <div class="info-section">
                          <p class="info-title" style="font-weight: bold; margin-bottom: 5px; color: black;"><strong>Description du Paylink:</strong></p>
  
                          <p class="info-content" style="color: black; text-align: justify;">{{ paylink.description }}</p>
                      </div>
                      <hr class="title-hr" />
                      <div class="info-section">
                          <p class="info-title" style=" margin-bottom: 5px; color: black;"><strong>Créé le </strong>: {{ new Date(paylink.created_at).toLocaleDateString() }}</p>
                          <hr class="title-hr" />
                      </div>
  
                      <div class="info-section">
                          <p class="info-title" style=" margin-bottom: 5px; color: black;"><strong>Expire le</strong> : {{ new Date(paylink.expiration_at).toLocaleDateString() }}</p>
                          <hr class="title-hr" />
                      </div>
  
                      <div class="info-section">
                          <p class="info-title" style=" margin-bottom: 5px; color: black;"><strong>Propriétaire</strong> : {{ paylink.client.nom }}</p>
                          <hr class="title-hr" />
                      </div>
  
                      <div class="info-section">
                          <p class="info-title" style=" margin-bottom: 5px; color: black;"><strong>Destinataire du paiement</strong> : MFS NERO TECHNOLOGIES COL SP / {{ paylink.client.nom }}</p>
                          <hr class="title-hr" />
                      </div>
  
                      <div class="info-section">
                          <p class="info-title" style=" margin-bottom: 5px; color: black;"><strong>Montant minimum accepté</strong> : {{ paylink.montant_min }} FCFA</p>
                          <hr class="title-hr" />
                      </div>
  
                      <!-- Bouton centré -->
                      <div class="d-flex justify-content-center">
                          <button v-if="paylink.status !== 'desactived' && new Date() < new Date(paylink.expiration_at)" class="btn btn-success mt-4" @click="showSupportModal" style="
                              background-color: #006666;
                              border-color: #006666;
                              font-size: 1.25rem;
                              padding: 10px 20px;
                              ">
                              Nouveau paiement <i class="bi bi-credit-card me-2"></i>
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  
      <div class="modal fade" id="supportModal" tabindex="-1" aria-labelledby="supportModalLabel" aria-hidden="true">
          <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header" style="background-color: #006666">
                      <h5 class="modal-title" id="supportModalLabel" style="color: white">
                          NOUVEAU PAIEMENT
                      </h5>
                  </div>
                  <div class="modal-body">
                      <ul class="nav nav-tabs" role="tablist">
                          <li class="nav-item">
                              <a class="nav-link" :class="{ active: selectedTab === 'MTN' }" @click.prevent="handleTabClick('MTN')" href="#">
                                  <img src="/images/logomtn.svg" alt="MTN" class="img-fluid" style="height: 50px; margin-right: 28px" />
                              </a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" :class="{ active: selectedTab === 'MOOV' }" @click.prevent="handleTabClick('MOOV')" href="#">
                                  <img src="/images/logomoov.png" alt="MOOV" class="img-fluid" style="height: 50px; margin-right: 28px" />
                              </a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link" :class="{ active: selectedTab === 'CELTIIS' }" @click.prevent="handleTabClick('CELTIIS')" href="#">
                                  <img src="/images/celtislogo.png" alt="CELTIIS" class="img-fluid" style="height: 50px; margin-right: 28px" />
                              </a>
                          </li>
                      </ul>
                      <div v-if="isUnavailable" class="alert alert-info mt-3" role="alert">
                          Non disponible
                      </div>
                      <div v-else>
  
                          <!-- Autres champs de saisie -->
                          <div class="mb-3">
                              <label for="nom" class="form-label">Nom</label>
                              <input type="text" class="form-control" id="nom" v-model="nom" placeholder="Entrez votre nom" required />
                          </div>
                          <div class="mb-3">
                              <label for="prenom" class="form-label">Prénoms</label>
                              <input type="text" class="form-control" id="prenom" v-model="prenom" placeholder="Entrez votre prénoms" required />
                          </div>
  
                          <!-- Champ de sélection pour le titre -->
                          <div v-if="paylink && paylink.client.user_id === '1bc80ee0-1f84-4f2d-bb27-82030bd453f9'">
                              <!-- Sélection du rôle -->
                              <div class="mb-3">
                                  <label for="title" class="form-label">Fonction</label>
                                  <select class="form-select" id="title" v-model="fonction" required>
                                      <option value="">Sélectionnez votre fonction</option>
                                      <option value="Adjoints au Maire">Adjoint au Maire</option>
                                      <option value="Chargés de Mission">Chargé de Mission</option>
                                      <option value="Chefs d'Arrondissement">Chefs d'Arrondissement</option>
                                      <option value="Chefs Division">Chef Division</option>
                                      <option value="Chefs Service">Chef Service</option>
                                      <option value="Conseillers Communaux">Conseiller Communal</option>
                                      <option value="Conseillers Techniques">Conseiller Technique</option>
                                      <option value="Députés">Député</option>
                                      <option value="Directeurs centraux et départementaux">Directeur central et départemental</option>
                                      <option value="Directeurs Généraux">Directeur Général</option>
                                      <option value="Directeurs Généraux Adjoints">Directeur Général Adjoint</option>
                                      <option value="Maires">Maire</option>
                                      <option value="Militants">Militant</option>
                                      <option value="Ministres">Ministre</option>
                                      <option value="Présidents de commission">Président de commission</option>
                                      <option value="Présidents et Membres d'Institution">Président et Membre d'Institution</option>
                                      <option value="Préfets">Préfet</option>
                                      <option value="Secrétaires Généraux">Secrétaire Général</option>
                                      <option value="Secrétaires Généraux Adjoints">Secrétaire Général Adjoint</option>
                                      <option value="Sympathisants">Sympathisant</option>
                                      <option value="Autres">Autre</option>
                                  </select>
  
                              </div>
                          </div>
  
                          <div class="mb-3">
                              <label for="email" class="form-label">E-mail</label>
                              <input type="email" class="form-control" id="email" v-model="email" placeholder="Entrez votre e-mail" required />
                          </div>
  
                          <!-- Choix de type de paiement (Radio buttons) -->
                          <div v-if="paylink && paylink.client.user_id === '1bc80ee0-1f84-4f2d-bb27-82030bd453f9'">
                              <div class="mb-3">
                                  <label class="form-label">Type de paiement</label>
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" id="contribution" value="Contribution" v-model="payment_type" />
                                      <label class="form-check-label" for="contribution">Contribution</label>
                                  </div>
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" id="souscription" value="Souscription" v-model="payment_type" />
                                      <label class="form-check-label" for="souscription">Souscription</label>
                                  </div>
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" id="cotisation" value="Cotisation" v-model="payment_type" />
                                      <label class="form-check-label" for="cotisation">Cotisation</label>
                                  </div>
                              </div>
                          </div>
  
                          <div class="mb-3">
                              <label for="amount" class="form-label">Montant</label>
                              <input type="number" class="form-control" id="amount" v-model="amount" :min="montant_min" />
                          </div>
  
                          <div class="mb-3">
                              <label for="amount" class="form-label">Frais</label>
                              <input type="number" class="form-control text-muted" id="amount" v-model="fees_added" readonly />
                          </div>
  
                          <div class="mb-3">
                              <label for="paymentNumber" class="form-label">Numéro de paiement</label>
                              <input type="text" v-model="paymentNumber" @blur="validateAndPrefixNumber" class="form-control" id="paymentNumber" placeholder="Ex:00000000" required />
                          </div>
                      </div>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-primary" @click="processPayment" style="background-color: #006666; border-color: #006666">
                          Payer {{ totalAmountWithFees }}
                      </button>
                      <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                          Quitter
                      </button>
                  </div>
              </div>
          </div>
      </div>
  
  </div>
  </template>
  
  <script>
  import axios from "@/axs/axios.js";
  import Head from "@/components/Head.vue";
  import Swal from "sweetalert2";
  import {
      ref,
      onMounted
  } from "vue";
  import {
      useRoute
  } from "vue-router";
  import {
      BACK_URL
  } from "@/entreprise_logo.js";
  export default {
      components: {
          Head,
      },
      setup() {
          const route = useRoute();
          const entreprise = ref(null);
          const amount = ref(null);
          const paymentNumber = ref("");
          const currency = ref("XOF");
          const externalId = ref("12345678");
          const payer = ref({
              partyIdType: "MSISDN",
              partyId: "",
          });
  
          const selectedTab = ref("MTN");
          const fonction = ref("");
          const payment_type = ref("");
          const nom = ref("");
          const prenom = ref("");
          const email = ref("");
          const clientId = ref(null);
          const paylinkId = ref(null);
          const totalAmountWithFees = ref(null);
          const frais = ref(null);
          const isCheckingStatus = ref(false);
          const isProcessingPayment = ref(false);
          const paymentReferenceId = ref(null);
          const isUnavailable = ref(false);
  
          const validateAndPrefixNumber = () => {
              if (paymentNumber.value && !paymentNumber.value.startsWith("229")) {
                  paymentNumber.value = `229${paymentNumber.value}`;
              }
          };
  
          const showSupportModal = () => {
              const supportModal = new bootstrap.Modal(
                  document.getElementById("supportModal")
              );
              supportModal.show();
          };
  
          const handleTabClick = (tab) => {
              if (tab === "MOOV" || tab === "CELTIIS") {
                  isUnavailable.value = true;
              } else {
                  isUnavailable.value = false;
              }
              selectedTab.value = tab;
          };
  
          const processPayment = async () => {
              if (amount.value <= 0 || !paymentNumber.value) {
                  Swal.fire({
                      icon: "warning",
                      title: "Champs invalides",
                      text: "Veuillez entrer un montant positif et un numéro de paiement.",
                  });
                  return;
              }
  
              payer.value.partyId = paymentNumber.value;
  
              Swal.fire({
                  title: "Paiement en cours",
                  text: "Votre paiement est en cours de traitement, veuillez patienter...",
                  icon: "info",
                  didOpen: () => {
                      Swal.showLoading(); // Affiche l'indicateur de chargement
                  },
              });
  
              try {
                  const data = {
                      amount: totalAmountWithFees.value,
                      paymentNumber: paymentNumber.value,
                      currency: currency.value,
                      externalId: externalId.value,
                      payer: payer.value,
                      nomdestinataire: `${nom.value} ${prenom.value}`,
                      client_id: clientId.value,
                      paylink_id: paylinkId.value,
                      mode: 1,
                      numero: paymentNumber.value,
                      fonction: fonction.value,
                      payment_type: payment_type.value,
                      operateur: "MTN",
                      email: email.value,
                  };
  
                  console.log(data);
                  const response = await axios.post("/process-payment-paylinks", data);
  
                  if (response.status === 200 || response.status === 202) {
                      paymentReferenceId.value = response.data.paiement.referenceId;
                      console.log(response.data.paiement.referenceId);
                      startCheckingStatus(paymentReferenceId.value);
  
                      Swal.close();
  
                      // Modal personnalisé avec SweetAlert
                      Swal.fire({
                          html: `
                        <div class="modal-content" style="background-color: #fff;">
                            <div class="modal-body text-center">
                              <!-- Indicateur de chargement -->
                              <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Chargement...</span>
                              </div>
                              <!-- Message de traitement -->
                              <p class="mt-3">Traitement du paiement en cours. Veuillez patienter...</p>
                              <!-- Affichage du montant -->
                              <h2 class="mt-4">Total à payer</h2>
                              <div class="amount">${amount.value} FCFA</div>
                              <!-- Icône visuelle -->
                              <div class="text-center mb-4">
                                <span class="hand-icon" style="font-size: 2rem;"></span>
                              </div>
                              <!-- Instructions pour l'utilisateur -->
                              <p class="mt-3"><strong>Veuillez consulter votre téléphone</strong></p>
                              <p>Entrez votre code de confirmation Mobile Money pour valider le paiement.</p>
                              <!-- Instructions alternatives -->
                              <div class="mt-4">
                                <p><strong>Vous n'avez pas reçu de demande de paiement ?</strong></p>
                                <p class="highlight-text">Tapez *880# option 8, option 2 puis 1</p>
                              </div>
                            </div>
                        </div>
                    `,
                          showConfirmButton: false,
                          allowOutsideClick: false,
                      });
                  }
              } catch (error) {
                  Swal.fire({
                      icon: "error",
                      title: "Erreur lors du paiement",
                      text: "Une erreur est survenue. Veuillez réessayer.",
                  });
                  console.error("Erreur lors du paiement :", error);
              }
          };
  
          const startCheckingStatus = (referenceId) => {
              isCheckingStatus.value = true;
              const maxDuration = 60000;
              const interval = 1000;
              const startTime = Date.now();
  
              const checkStatus = async () => {
                  if (!isCheckingStatus.value) return;
  
                  try {
                      const response = await axios.get(
                          `/check-payment-status-paylinks/${referenceId}`
                      );
                      if (response.status === 200) {
                          const status = response.data.status;
                          if (status === "SUCCESSFUL") {
                              Swal.fire({
                                  icon: "success",
                                  title: "Paiement réussi",
                                  confirmButtonColor: "#006666",
                              });
  
                              window.location.reload();
                              $("#addcard").modal("hide");
                              isProcessingPayment.value = false;
                              stopCheckingStatus();
                          } else if (status === "FAILED") {
                              Swal.fire({
                                  icon: "error",
                                  title: "Paiement échoué",
                                  text: "Votre paiement a échoué. Veuillez réessayer.",
                                  confirmButtonColor: "#006666",
                              });
                              isProcessingPayment.value = false;
                              stopCheckingStatus();
                          } else if (
                              status === "PENDING" &&
                              Date.now() - startTime < maxDuration
                          ) {
                              setTimeout(() => checkStatus(), interval);
                          } else {
                              Swal.fire({
                                  icon: "warning",
                                  title: "Délai d'attente dépassé",
                                  text: "Le paiement est toujours en cours. Veuillez réessayer.",
                                  confirmButtonColor: "#006666",
                              });
                              isProcessingPayment.value = false;
                              stopCheckingStatus();
                          }
                      }
                  } catch (error) {
                      console.error(
                          "Erreur lors de la vérification du statut du paiement :",
                          error
                      );
                      if (Date.now() - startTime < maxDuration) {
                          setTimeout(() => checkStatus(), interval);
                      } else {
                          Swal.fire({
                              icon: "warning",
                              title: "Délai d'attente dépassé",
                              text: "Le paiement est toujours en cours. Veuillez réessayer.",
                              confirmButtonColor: "#006666",
                          });
                          isProcessingPayment.value = false;
                          stopCheckingStatus();
                      }
                  }
              };
  
              checkStatus();
          };
  
          const stopCheckingStatus = () => {
              isCheckingStatus.value = false;
          };
  
          onMounted(() => {
              // Appeler fetchPaylinkDetails pour obtenir client_id
              const fetchPaylinkDetails = async () => {
                  try {
                      const id = route.params.id;
                      paylinkId.value = id;
                      const response = await axios.get(`/show-paylinks/${id}`);
                      entreprise.value = response.data;
                      clientId.value = entreprise.value.client_id;
                  } catch (error) {
                      console.error("Erreur lors de la récupération des détails du paylink :", error);
                  }
              };
  
              fetchPaylinkDetails();
          });
  
          return {
              entreprise,
              amount,
              paymentNumber,
              currency,
              externalId,
              payer,
              selectedTab,
              nom,
              prenom,
              email,
              frais,
              totalAmountWithFees,
              payment_type,
              fonction,
              clientId,
              paylinkId,
              showSupportModal,
              processPayment,
              validateAndPrefixNumber,
              startCheckingStatus,
              stopCheckingStatus,
              BACK_URL: BACK_URL,
              handleTabClick,
              isUnavailable,
          };
      },
      data() {
          return {
              paylink: null,
              loading: true,
              error: null,
              client_id: null,
          };
      },
      mounted() {
          this.fetchPaylinkDetails();
      },
      methods: {
          async fetchPaylinkDetails() {
              try {
                  const paylinkId = this.$route.params.id;
                  const response = await axios.get(`/show-paylinks/${paylinkId}`);
                  this.paylink = response.data;
                  this.client_id = this.paylink.client_id;
  
                  // Vérification de 'total_amount' et assignation
                  if (response.data.montant_min) {
                      this.amount = parseFloat(response.data.montant_min);
                  }
  
                  if (response.data.fees_added) {
                      this.fees_added = parseFloat(response.data.fees_added);
                  }
  
                  if (response.data.total_amount && response.data.fees_added) {
                      this.totalAmountWithFees = this.amount + this.fees_added;
                  }
                  console.log(this.totalAmountWithFees);
              } catch (error) {
                  this.error = "Erreur lors de la récupération des détails du paylink";
                  console.error(error);
              } finally {
                  this.loading = false;
              }
          }
  
      },
  };
  </script>
  
  <style scoped>
  .image-container {
      max-width: 100%;
      overflow: hidden;
  }
  
  .image-container img {
      width: 100%;
      height: auto;
  }
  
  .paylink-image {
      max-width: 300px;
      width: 100%;
      height: auto;
  }
  
  @media (min-width: 768px) {
      .paylink-image {
          max-width: 400px;
      }
  }
  
  @media (min-width: 1200px) {
      .paylink-image {
          max-width: 500px;
      }
  }
  </style>
  