<template>
<main class="flex-shrink-0">

    <Head />
    <!-- Header -->
    <header class="py-5">
        <div class="container px-5">
            <div class="row gx-5 align-items-center">
                <div class="col-xxl-5">
                    <div class="text-center text-xxl-start">
                        <p class="fs-3 fw-light text-dark">
                            La solution africaine de paiement et de suivi des transactions Money Money
                        </p>
                        <h1 class="display-5 fw-bolder mb-2">
                            <span class="text-gradient d-inline" style="color: #006666">AfrikMoney</span>
                        </h1>
                    </div>
                </div>
                <div class="col-xxl-7">
                    <div class="d-flex justify-content-center mt-5 mt-xxl-0">
                        <div class="profile bg-gradient-primary-to-secondary text-center">
                            <img class="profile-img" src="images/LOGOAFRICAMONEY.png" alt="Logo Africamoney" />
                        </div>
                    </div>

                    <!-- Ajouter text-center ici pour centrer les boutons -->
                    <div class="d-flex justify-content-center mt-4">
                        <div style="display: flex; gap: 10px;">
                            <a href="/connexion" class="btn btn-md rounded-pill" style="background-color: #006666; color: white; font-size: 14px; padding: 10px 20px;">Se connecter</a>
                            <a href="/paylink-user-registration" class="btn btn-md rounded-pill" style="background-color: #006666; color: white; font-size: 14px; padding: 10px 20px;">Commencer maintenant</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </header>

    <section class="hero bg-dark text-white text-center py-5">
        <div class="container">
            <h1 class="display-4 fw-bold">Recevez de l'argent en toute sécurité avec un PayLink personnalisé</h1>
            <p class="lead">Avec votre paylink, gérer en toute simplicité le financement de vos projets et évènements.</p>
            <a href="/paylink-user-registration" class="btn btn-lg rounded-pill mt-4" style="background-color: #006666; color: white;">Démarrer aujourd'hui!</a>

        </div>
    </section>

    <!-- Section 2: Comment ça marche -->
    <section class="how-it-works py-5 bg-light">
    <div class="container">
        <div class="row text-center mb-5">
            <h2 class="fw-bold">Comment ça marche ?</h2>
            <p class="text-muted">Avec un PayLink, la collecte de fonds n’a jamais été aussi simple et flexible.</p>
        </div>
        <div class="row text-center">
            <div class="col-md-4">
                <img src="https://via.placeholder.com/150?text=1" class="img-fluid mb-3" alt="Étape 1">
                <h5>Créez votre lien personnalisé</h5>
                <p>Configurez un lien de paiement pour un événement en quelques clics.</p>
            </div>
            <div class="col-md-4">
                <img src="https://via.placeholder.com/150?text=2" class="img-fluid mb-3" alt="Étape 2">
                <h5>Partagez-le avec vos proches</h5>
                <p>Envoyez votre lien PayLink via WhatsApp, email ou sur les réseaux sociaux.</p>
            </div>
            <div class="col-md-4">
                <img src="https://via.placeholder.com/150?text=3" class="img-fluid mb-3" alt="Étape 3">
                <h5>Recevez vos fonds instantanément</h5>
                <p>Suivez vos collectes et recevez des paiements directement sur votre compte.</p>
            </div>
        </div>
    </div>
</section>


    <!-- Section 3: Pourquoi choisir PayLinks -->
    <section class="why-paylinks py-5">
        <div class="container">
            <div class="row text-center mb-5">
                <h2 class="fw-bold">Pourquoi utiliser un PayLinks ?</h2>
            </div>
            <div class="row text-center">
                <div class="col-md-4">
                    <i class="fas fa-lock fa-3x mb-3"></i>
                    <h5>Sécurité maximale</h5>
                    <p>PayLinks garantit des transactions sécurisées et rapides pour vos collectes.</p>
                </div>
                <div class="col-md-4">
                    <i class="fas fa-mobile-alt fa-3x mb-3"></i>
                    <h5>Facile d'utilisation</h5>
                    <p>Créez, partagez et gérez vos PayLinks depuis n'importe quel appareil.</p>
                </div>
                <div class="col-md-4">
                    <i class="fas fa-globe fa-3x mb-3"></i>
                    <h5>Partout dans le monde</h5>
                    <p>Recevez des fonds de n'importe où, à tout moment, en quelques secondes.</p>
                </div>
            </div>
        </div>
    </section>

    <!-- Section 4: Appel à l'action -->
    <section class="cta py-5 text-white text-center" id="start">
        <div class="container">
            <h2 class="fw-bold">Prêt à recevoir des fonds pour votre événement ?</h2>
            <p>Créez votre premier lien de paiement pour collecter des fonds dès maintenant !</p>
            <a href="/paylink-user-registration" class="btn btn-light btn-lg rounded-pill mt-4" style="background-color: #006666; color: white;">Créer mon PayLink</a>
        </div>
    </section>
    <!-- Footer -->
    <footer class="py-4 mt-auto" style="background-color: #006666; color: white">
        <div class="container">
            <div class="row align-items-center justify-content-center justify-content-sm-between text-center text-sm-start">
                <div class="col-auto">
                    <p class="small text-white m-0">&copy; AfricaMoney {{ new Date().getFullYear() }}</p>
                </div>
                <div class="col-auto">
                    <a class="small text-white text-decoration-none mx-2" href="#!">Confidentialité</a>
                    <a class="small text-white text-decoration-none mx-2" href="#!">Termes et Conditions</a>
                </div>
            </div>
        </div>
    </footer>
</main>
</template>

<script>
import Head from "@/components/Head.vue";
import axios from "@/axs/axios.js";

export default {
    components: {
        Head
    },
    data() {
        return {
            paylinkTotal: 0,
            totalMontant: 0,
            transactionCount: 0,
        };
    },
    mounted() {
        this.fetchPaylinkStats();
    },
    methods: {
        async fetchPaylinkStats() {
            try {
                const response = await axios.get("/paylinks-total");
                this.paylinkTotal = response.data.paylink_total;
                this.totalMontant = response.data.total_montant;
                this.transactionCount = response.data.transaction_count;
            } catch (error) {
                console.error(
                    "Erreur lors de la récupération des statistiques :",
                    error
                );
            }
        },
    },
};
</script>

<style>
.section-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.section-subtitle {
    font-size: 1.1rem;
    color: #666;
}

.card {
    transition: transform 0.3s ease-in-out;
}

.card:hover {
    transform: translateY(-10px);
}

.service-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;
    margin: 0 auto;
}

.hero {
    background: black;
    color: white;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
