<template>
  <main class="main">
    <Head />
    <!-- Section 1: Hero -->
    <br />
    <section class="hero bg-dark text-white text-center py-5">
      <div class="container">
        <h1 class="display-4 fw-bold">Prenez des engagements facilement avec E-Engagement</h1>
        <p class="lead">Simplifiez vos paiements pour les services éducatifs et restez à jour avec vos échéances.</p>
        <a href="/sengager" class="btn btn-lg rounded-pill mt-4" style="background-color: #006666; color: white;">S'engager</a>
      </div>
    </section>

    <!-- Section 2: Comment ça marche -->
    <section class="how-it-works py-5 bg-light">
      <div class="container">
        <div class="row text-center mb-5">
          <h2 class="fw-bold">Comment ça marche ?</h2>
          <p class="text-muted">Avec E-Engagement, gérer vos paiements n’a jamais été aussi simple et organisé.</p>
        </div>
        <div class="row text-center">
          <div class="col-md-4">
            <img src="https://via.placeholder.com/150" class="img-fluid mb-3" alt="Choisissez une entité">
            <h5>Choisissez votre entité</h5>
            <p>Sélectionnez l'école ou l'organisation pour laquelle vous souhaitez prendre un engagement.</p>
          </div>
          <div class="col-md-4">
            <img src="https://via.placeholder.com/150" class="img-fluid mb-3" alt="Définissez des échéances">
            <h5>Définissez des échéances</h5>
            <p>Configurez des dates de rappel pour vos paiements afin de ne rien manquer.</p>
          </div>
          <div class="col-md-4">
            <img src="https://via.placeholder.com/150" class="img-fluid mb-3" alt="Recevez des rappels">
            <h5>Recevez des rappels</h5>
            <p>Obtenez des notifications automatiques pour vos échéances de paiement.</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Section 3: Pourquoi choisir E-Engagement -->
    <section class="why-e-engagement py-5">
      <div class="container">
        <div class="row text-center mb-5">
          <h2 class="fw-bold">Pourquoi utiliser E-Engagement ?</h2>
        </div>
        <div class="row text-center">
          <div class="col-md-4">
            <i class="fas fa-check-circle fa-3x mb-3"></i>
            <h5>Simplicité</h5>
            <p>Gérez facilement vos engagements depuis un seul endroit.</p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-bell fa-3x mb-3"></i>
            <h5>Rappels Automatisés</h5>
            <p>Ne manquez jamais une échéance grâce à nos rappels.</p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-users fa-3x mb-3"></i>
            <h5>Contribuez à l'éducation</h5>
            <p>Soutenez les écoles et organisations tout en gérant vos paiements.</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Section 4: Appel à l'action -->
    <section class="cta py-5 text-black text-center" id="start">
      <div class="container">
        <p>Commencez à gérer vos paiements facilement et efficacement.</p>
        <a href="/sengager" class="btn btn-light btn-lg rounded-pill mt-4" style="background-color: #006666; color: white;">Commencer</a>
      </div>
    </section>
  </main>

  <footer class="py-4 mt-auto" style="background-color: #006666; color: white;">
    <div class="container">
      <div class="row align-items-center justify-content-center justify-content-sm-between text-center text-sm-start">
        <div class="col-auto">
          <div class="small m-0" v-text="'© Africamoney ' + new Date().getFullYear()"></div>
        </div>
        <div class="col-auto">
          <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Confidentialité</a>
          <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Termes et conditions</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Head from "@/components/Head.vue";
export default {
  components: { Head },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.hero {
    background: black;
    color: white;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
