<template>
<div>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="row">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">RECHERCHER</h5>
                            <hr> <!-- Horizontal line added here -->
                            <div class="row mb-4">
                                <div class="col-md-4">
                                    <label for="startDate">Date de début</label>
                                    <input type="date" id="startDate" class="form-control">
                                </div>
                                <div class="col-md-4">
                                    <label for="endDate">Date de fin</label>
                                    <input type="date" id="endDate" class="form-control">
                                </div>
                                <div class="col-md-4">
                                    <label for="searchNumber">Contact</label>
                                    <input type="text" class="form-control" id="searchNumber">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row my-1">
                    <div class="col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">GERER MES CONTACTS</h5>
                            <hr> <!-- Horizontal line added here -->
                            <div class="d-flex flex-wrap justify-content-start">
                                <button @click="addContact" class="btn btn-sm me-2 mb-2" style="background-color: #006666; color: white;">
                                    <i class="bi bi-person-plus"></i> Ajouter un Contact
                                </button>
                                <button @click="editContact" class="btn btn-sm me-2 mb-2" style="background-color: #006666; color: white;">
                                    <i class="bi bi-pencil"></i> Modifier le Contact
                                </button>
                                <button @click="deleteContact" class="btn btn-sm me-2 mb-2" style="background-color: #006666; color: white;">
                                    <i class="bi bi-trash"></i> Supprimer le Contact
                                </button>
                                <input type="file" @change="handleFileUpload" class="d-none" ref="fileInput" accept=".xlsx, .xls" />
                                <button @click="triggerFileInput" class="btn btn-sm me-2 mb-2" style="background-color: #006666; color: white;">
                                    <i class="bi bi-file-earmark-arrow-up"></i> Importer des Contacts
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row my-1">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">MES CONTACTS</h5>
                            <hr> <!-- Horizontal line added here -->
                            <div class="table-responsive">
                                <table class="account-table table">
                                    <thead style="background-color: #006666;">
                                        <tr>
                                            <th scope="col" style="color: white;">N°</th>
                                            <th scope="col" style="color: white;">NOM</th>
                                            <th scope="col" style="color: white;">PRENOM</th>
                                            <th scope="col" style="color: white;">NUMERO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</div>
</template>

    
<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx'; // Importer SheetJS

export default {
    components: {
        Header,
        SideBar
    },
    data() {
        return {
            // Ajoutez des données si nécessaire
        };
    },
    mounted() {
        const userDataString = sessionStorage.getItem('userData');
        if (userDataString) {
            try {
                const userData = JSON.parse(userDataString);
                this.clientId = userData.additional_info.id;
                this.fetchPayoutRequests(this.currentPage);
            } catch (error) {
                console.error('Erreur lors du parsing de userData depuis sessionStorage:', error);
            }
        } else {
            console.error('userData n\'est pas disponible dans sessionStorage');
        }
    },
    methods: {
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, {
                        type: 'array'
                    });
                    const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
                    const contacts = XLSX.utils.sheet_to_json(firstSheet, {
                        header: 1
                    });
                    console.log(contacts);

                };
                reader.readAsArrayBuffer(file);
            }
        }
    }
};
</script>

    
<style></style>
