<template>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">Recherche</h5>
                            <div class="row mb-4">
                                <div class="col-md-12">
                                    <label for="searchDossier">Dossier</label>
                                    <input type="text" class="form-control" id="searchDossier">
                                </div>
                            </div>
                            <button type="button" class="btn btn-primary mb-3" @click="openModal">Ajouter</button>
                        </div>
                    </div>
                </div>

                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">Entités</h5>
                            <div class="table-responsive">
                                <table class="account-table table">
                                    <thead style="background-color: #006666;">
                                        <tr>
                                            <th scope="col" style="color: white;">N°</th>
                                            <th scope="col" style="color: white;">Entité</th>
                                            <th scope="col" style="color: white;">Adresse</th>
                                            <th scope="col" style="color: white;">E-mail</th>
                                            <th scope="col" style="color: white;">Téléphone</th>
                                            <th scope="col" style="color: white;">Type</th>
                                            <th scope="col" style="color: white;">IFU</th>
                                            <th scope="col" style="color: white;">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(entite, index) in entites.data" :key="entite.id">
                                            <td>{{ entites.from + index }}</td>
                                            <td>{{ entite.nom }}</td>
                                            <td>{{ entite.adresse }}</td>
                                            <td>{{ entite.email }}</td>
                                            <td>{{ entite.telephone }}</td>
                                            <td>{{ entite.type_entite }}</td>
                                            <td>{{ entite.numero_registre_commerce }}</td>
                                            <td>
                                                <a :href="'/admin/entite-details/' + entite.id"
                                                    class="btn btn-info btn-sm">
                                                    <i class="fas fa-eye"></i>
                                                </a>
                                                <span>&nbsp;</span>
                                                <button @click="modifierEntite(entite.id)"
                                                    class="btn btn-warning btn-sm">
                                                    <i class="fas fa-edit"></i>
                                                </button>
                                                <span>&nbsp;</span>
                                                <button @click="supprimerEntite(entite.id)"
                                                    class="btn btn-danger btn-sm">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <nav aria-label="Page navigation">
                                    <ul class="pagination">
                                        <li class="page-item" :class="{ disabled: !entites.prev_page_url }">
                                            <a class="page-link" href="#" @click.prevent="loadEntites(entites.prev_page_url)">Previous</a>
                                        </li>
                                        <li class="page-item" :class="{ active: link.active }" v-for="link in entites.links" :key="link.label">
                                            <a class="page-link" href="#" @click.prevent="loadEntites(link.url)">{{ link.label }}</a>
                                        </li>
                                        <li class="page-item" :class="{ disabled: !entites.next_page_url }">
                                            <a class="page-link" href="#" @click.prevent="loadEntites(entites.next_page_url)">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Modal -->
                <div class="modal fade" id="entiteModal" tabindex="-1" aria-labelledby="entiteModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg"> <!-- Ajoute ici 'modal-lg' pour agrandir le modal -->
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="entiteModalLabel">Ajouter une entité</h5>
                                <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="storeEntite">
                                    <div class="mb-3">
                                        <label for="nom" class="form-label">Nom</label>
                                        <input type="text" class="form-control" id="nom" v-model="entite.nom" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="adresse" class="form-label">Adresse</label>
                                        <input type="text" class="form-control" id="adresse" v-model="entite.adresse" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="email" class="form-label">E-mail</label>
                                        <input type="email" class="form-control" id="email" v-model="entite.email" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="telephone" class="form-label">Téléphone</label>
                                        <input type="text" class="form-control" id="telephone" v-model="entite.telephone" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="type_entite" class="form-label">Type d'entité</label>
                                        <input type="text" class="form-control" id="type_entite" v-model="entite.type_entite" required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="ifu" class="form-label">IFU</label>
                                        <input type="text" class="form-control" id="ifu" v-model="entite.numero_registre_commerce">
                                    </div>
                                    <button type="submit" class="btn btn-primary">Enregistrer</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </main>
        </div>
    </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Swal from 'sweetalert2';
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";

export default {
    components: { Header, Footer, SideBar },
    data() {
        return {
            entites: {
                data: [],
                links: [],
                from: 1,
                next_page_url: null,
                prev_page_url: null
            },
            entite: {
                nom: '',
                adresse: '',
                email: '',
                telephone: '',
                type_entite: '',
                numero_registre_commerce: '',
                user_id : "00991e33-783f-44cb-a4c0-99d4295b3e06"
            }
        };
    },
    mounted() {
        this.loadEntites();
    },
    methods: {
        openModal() {
            // Afficher le modal via Bootstrap
            var modal = new bootstrap.Modal(document.getElementById('entiteModal'));
            modal.show();
        },
        closeModal() {
            // Fermer le modal via Bootstrap
            var modal = bootstrap.Modal.getInstance(document.getElementById('entiteModal'));
            modal.hide();
            this.resetForm();
        },
        resetForm() {
            this.entite = {
                nom: '',
                adresse: '',
                email: '',
                telephone: '',
                type_entite: '',
                numero_registre_commerce: ''
            };
        },
        storeEntite() {

            axios.post('/entites', this.entite)
                .then(response => {
                    Swal.fire({
                        title: 'Succès!',
                        text: 'Entité enregistrée avec succès!',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        this.loadEntites();
                        this.closeModal();
                    });
                })
                .catch(error => {
                    console.error(error);
                    Swal.fire({
                        title: 'Erreur!',
                        text: 'Une erreur s\'est produite lors de l\'enregistrement de l\'entité.',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                });
        },

        loadEntites() {
            axios.get('/entites')
                .then(response => {
                    this.entites = response.data.data; // Met à jour les données des entités
                })
                .catch(error => {
                    console.error(error);
                    alert('Une erreur s\'est produite lors de la récupération des entités.');
                });
        }
    }
};
</script>

<style scoped>
/* Ajoute ici tes styles personnalisés si nécessaire */
</style>
