<template>
  <main class="main">
    <Head />
    <div class="container">
      <div class="row justify-content-center align-items-center min-vh-100 py-5">
        <div class="col-md-10 col-lg-6 col-xl-5"> <!-- Réduction de la largeur ici -->
          <div class="shadow p-3 mb-5 bg-body rounded">
            <div class="text-center mb-7">
              <!-- Ajout de l'icône ici -->
              <i class="bi bi-person-circle" style="font-size: 3rem; color: #006666;"></i>
              <h3 class="text-1000 mt-2">SE CONNECTER</h3>
            </div>
            <form @submit.prevent="loginUser">
              <div class="mb-3 text-start">
                <label class="form-label" for="email">E-mail</label>
                <div class="form-icon-container">
                  <input class="form-control form-icon-input" v-model="email" type="email" placeholder="name@example.com" />
                </div>
              </div>
              <div class="mb-3 text-start">
                <label class="form-label" for="password">Mot de passe</label>
                <div class="form-icon-container position-relative">
                  <input
                    class="form-control form-icon-input"
                    v-model="password"
                    :type="passwordVisible ? 'text' : 'password'"
                    placeholder="mot de passe"
                  />
                  <button
                    type="button"
                    class="btn btn-outline-secondary position-absolute top-50 end-0 translate-middle-y"
                    @click="togglePasswordVisibility"
                    style="z-index: 2;"
                  >
                    <i :class="passwordVisible ? 'bi bi-eye-slash' : 'bi bi-eye'"></i>
                  </button>
                </div>
                <br />
                <div class="col-auto">
                  <a class="fs--1 fw-semi-bold" href="/mot-de-passe-oublier">Mot de passe oublié ?</a>
                </div>
              </div>
              <button class="btn btn-secondary w-100 mb-3" style="background-color: #006666;" type="submit" :disabled="loading">
                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-else>Se connecter</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
  <footer class="py-4 mt-auto" style="background-color: #006666; color: white;">
    <div class="container">
        <div class="row align-items-center justify-content-center justify-content-sm-between text-center text-sm-start">
            <div class="col-auto">
                <div class="small m-0" v-text="'© Africamoney ' + new Date().getFullYear()"></div>
            </div>
            <div class="col-auto">
                <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Confidentialité</a>
                <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Terms et conditions</a>
            </div>
        </div>
    </div>
</footer>

</template>


<script>
import axios from "@/axs/axios.js";
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import Head from "@/components/Head.vue";

export default {
  components: { Head },
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      passwordVisible: false, // État pour gérer la visibilité du mot de passe
    };
  },
  methods: {
    async loginUser() {
      try {
        this.loading = true;

        const response = await axios.post('/login', {
          email: this.email,
          password: this.password,
        });

        if (response.data && response.data.token && response.data.user) {
          // Stocker le token dans sessionStorage
          sessionStorage.setItem('authToken', response.data.token);
          sessionStorage.setItem('userData', JSON.stringify(response.data.user));
          // Rediriger l'utilisateur en fonction du rôle
          const role_id = response.data.user.role_id;

          if (role_id === "b1a00df2-3f9e-4875-9d39-643f11609fd3") {
            this.$router.push('/client/dashboard'); // CLIENT
          } else if (role_id === "64d28e8f-816f-4804-afe8-b77594cbe5db") {
            this.$router.push('/admin/admin'); // ADMIN
          } else { // SERVICE CLIENT
            this.$router.push('/service-client/mon-espace');
          }
        } else {
          console.error('Échec de l\'authentification. Réponse de l\'API:', response.data);
          // Afficher une alerte SweetAlert en cas d'échec de connexion
          Swal.fire({
            icon: 'error',
            title: 'E-mail ou mot de passe incorrect',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
          });
        }
      } catch (error) {
        console.error('Erreur lors de la requête:', error);
        // Afficher une alerte SweetAlert en cas d'erreur de requête
        Swal.fire({
          icon: 'error',
          title: 'Erreur lors de la requête',
          text: 'Une erreur s\'est produite. Veuillez réessayer plus tard.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000
        });
      } finally {
        this.loading = false;
      }
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    }
  },
};
</script>


<style>

</style>
