<template>
    <main class="main">
        <Head />
        <div class="container">
            <div class="row justify-content-center align-items-center min-vh-100 py-5">
                <div class="col-md-10 col-lg-7 col-xl-6">
                    <div class="shadow p-3 mb-5 bg-body rounded">
                        <div class="text-center mb-7">
                            <!-- Icône de réinitialisation de mot de passe -->
                            <i class="bi bi-key" style="font-size: 3rem; color: #006666;"></i>
                            <h3 class="text-1000 mt-2">Mot de passe oublié</h3>
                        </div>
                        <form @submit.prevent="resetPassword">
                            <div class="mb-3 text-start">
                                <label class="form-label" for="email">E-mail</label>
                                <div class="form-icon-container">
                                    <input class="form-control form-icon-input" v-model="email" id="email" type="email"
                                        placeholder="afrikmoney@example.com" required />
                                </div>
                            </div>
                            <button class="btn btn-secondary w-100 mb-3" style="background-color: #006666;" type="submit" :disabled="loading">
                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                <span v-else>Réinitialiser</span>
                            </button>
                        </form>
                        <div class="text-center">
                            <a class="fs--1 fw-bold" href="/connexion">Se connecter</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <footer class="py-4 mt-auto" style="background-color: #006666; color: white;">
    <div class="container">
        <div class="row align-items-center justify-content-center justify-content-sm-between text-center text-sm-start">
            <div class="col-auto">
                <div class="small m-0" v-text="'© Africamoney ' + new Date().getFullYear()"></div>
            </div>
            <div class="col-auto">
                <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Confidentialité</a>
                <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Terms et conditions</a>
            </div>
        </div>
    </div>
</footer>

</template>

<script>
import axios from "@/axs/axios.js";
import Head from "@/components/Head.vue";
import Swal from 'sweetalert2';

export default {
    components: { Head },
    data() {
        return {
            email: '',
            loading: false
        };
    },
    methods: {
        async resetPassword() {
            try {
                this.loading = true;
                const response = await axios.post('/reset-password', { email: this.email });
                
                if (response.data.status === true) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Succès',
                        text: 'Un e-mail a été envoyé à votre adresse e-mail.',
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erreur',
                        text: response.data.message,
                    });
                }
            } catch (error) {
                console.error('Error:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: 'Une erreur s\'est produite. Veuillez réessayer plus tard.',
                });
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style>
/* Styles personnalisés si nécessaire */
</style>
