<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Chart from "chart.js/auto";
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import { BACK_URL } from "@/entreprise_logo.js";
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  components: { Header, Footer, SideBar, QrcodeStream },
  data() {
    return {
      user: null,
      totalReceivedAmount: null,
      BACK_URL: BACK_URL,
      paymentLinkData: {
        objet: '',
        montant_minimum: '',
        numero: '',
        reponse: '',
        message_bienvenue: '',
        reponse_sim: '',
        statut: ''
      }
    };
  },
  methods: {
    async fetchUserProfile() {
      try {
        const response = await axios.get("/profile", {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
          },
        });

        if (response.data && response.data.user) {
          this.user = response.data.user;
        } else {
          console.error(
            "Échec de la récupération du profil. Réponse de l'API:",
            response.data
          );
        }
      } catch (error) {
        console.error(
          "Erreur lors de la requête de récupération du profil:",
          error
        );
      }
    },

    async copyPaymentLink() {
      try {
        const userData = JSON.parse(sessionStorage.getItem("userData"));
        const entrepriseId = userData.entreprise.id;
        const paymentLink = `https://afrikmoney.com/pay/${entrepriseId}`;

        await navigator.clipboard.writeText(paymentLink);

        Swal.fire({
          title: "Succès !",
          text: "Le lien de paiement a été copié dans le presse-papiers.",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
      } catch (error) {
        console.error("Erreur lors de la copie du lien de paiement :", error);
        Swal.fire({
          title: "Erreur !",
          text: "Une erreur est survenue lors de la copie du lien de paiement.",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },

    async fetchTotalReceivedAmount() {
      try {
        const response = await axios.get("/statistiques-user-africamoney");
        if (response.data && response.data.status === true) {
          this.totalReceivedAmount = response.data.total_received_amount;
          this.updatePieChart();
        } else {
          console.error(
            "Échec de la récupération des données de montant total reçu. Réponse de l'API:",
            response.data
          );
        }
      } catch (error) {
        console.error(
          "Erreur lors de la requête de récupération des données de montant total reçu:",
          error
        );
      }
    },

    // Méthode pour ouvrir le modal
    openPaymentLinkModal() {
      const modal = new bootstrap.Modal(document.getElementById('paymentLinkModal'));
      modal.show();
    },
    // Méthode pour traiter le formulaire du modal
    submitPaymentLinkForm() {
      // Traite les données du formulaire ici
      console.log(this.paymentLinkData);

      // Exemple d'alerte avec SweetAlert
      Swal.fire({
        title: 'Succès !',
        text: 'Les informations ont été enregistrées.',
        icon: 'success',
      });

      // Optionnel : Masquer le modal après la soumission
      const modal = bootstrap.Modal.getInstance(document.getElementById('paymentLinkModal'));
      modal.hide();
    },

    generatePDF() {
      // Affichez l'alerte SweetAlert
      Swal.fire({
        title: "Exportation",
        text: "Êtes-vous sûr de vouloir télecharger le guide ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Exporter",
        cancelButtonText: "Annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          const doc = new jsPDF();
          const totalPagesExp = "{total_pages_count_string}";
          let totalPages = 1;

          doc.setFillColor(0, 102, 102);
          doc.rect(0, 0, doc.internal.pageSize.width, 40, "F");
          const imgDataLeft = "../images/LOGOAFRICAMONEY.png";
          doc.addImage(imgDataLeft, "JPEG", 25, 10, 23, 23);
          const middleX = doc.internal.pageSize.width / 2;
          const middleY = 23;
          const text = "GUIDE D'UTULISATON";
          doc.setTextColor("#ffffff");
          doc.text(text, middleX, middleY, { align: "center" });
          doc.setTextColor(0, 0, 0);

          doc.autoTable({
            startY: 45,
            headStyles: { fillColor: [0, 102, 102], textColor: "#ffffff" },
            didDrawPage: function (data) {
              totalPages = doc.internal.getNumberOfPages();
              const bottomMargin = 10;
              doc.setFontSize(6);
              doc.text(
                "Page " + data.pageNumber + " sur " + totalPages,
                14,
                doc.internal.pageSize.height - bottomMargin
              );
              const currentDate = new Date();
              const dateString = currentDate.toLocaleDateString();
              const timeString = currentDate.toLocaleTimeString();
              doc.text(
                "imprimé le: " + dateString + " à " + timeString,
                14,
                doc.internal.pageSize.height - bottomMargin + 5
              ); // Décalage vertical pour la deuxième ligne
            },
          });

          doc.save("guide_dutulisation.pdf");
          Swal.fire({
            title: "Succès !",
            text: "L'exportation en PDF a réussi.",
            icon: "success",
          });
        }
      });
    },

    updatePieChart() {
      const pieChartData = {
        labels: ["MTN MONEY", "MOOV MONEY", "CELTIIS CASH", "FACT"],
        datasets: [
          {
            data: [
              this.totalReceivedAmount["MTN MONEY"],
              this.totalReceivedAmount["MOOV MONEY"],
              this.totalReceivedAmount["CELTIIS CASH"],
              this.totalReceivedAmount["DGI"],
            ],
            backgroundColor: ["#ffd014", "#FF8C00", "#000080", "#000"],
          },
        ],
      };

      const pieChartConfig = {
        type: "doughnut",
        data: pieChartData,
        options: {
          plugins: {
            legend: {
              display: true,
            },
            datalabels: {
              display: true,
              color: "white",
              backgroundColor: "#333",
              borderRadius: 5,
              padding: {
                top: 5,
                right: 5,
                bottom: 5,
                left: 5,
              },
            },
          },
        },
      };

      const ctx1 = document.getElementById("pie-chart").getContext("2d");

      new Chart(ctx1, pieChartConfig);
    },
  },

  mounted() {
    this.fetchUserProfile();
    this.fetchTotalReceivedAmount();
  },
};
</script>


<template>
  <Header />
  <div class="container-fluid">
    <div class="row">
      <SideBar />
      <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
        <div class="title-group mb-3">
          <h5 class="h5 mb-0 text-center" v-if="user">
            Bonjour {{ user.entreprise.entreprise }}, bienvenue sur Africamoney
            !
          </h5>
        </div>

        <div class="row my-4">
          <div class="col-lg-7 col-12">
            <div class="custom-block custom-block-balance">
              <small>Solde</small>

              <h2 class="mt-2 mb-3">
                {{
                  totalReceivedAmount
                    ? totalReceivedAmount.total_received
                    : "Chargement..."
                }}
                FCFA
              </h2>

              <div class="d-flex">
                <div>
                  <small>Date d'inscription</small>
                  <p v-if="user">
                    {{
                      new Date(user.created_at).toLocaleDateString("fr-FR", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })
                    }}
                    à
                    {{
                      new Date(user.created_at).toLocaleTimeString("fr-FR", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    }}
                  </p>
                </div>
              </div>
            </div>

            <div class="custom-block bg-white">
              <h5 class="mb-4">Statistiques</h5>
              <canvas id="pie-chart"></canvas>
            </div>
          </div>

          <div class="col-lg-5 col-12">
            <div class="custom-block custom-block-profile-front custom-block-profile text-center bg-white"
              v-if="user && user.entreprise">
              <div class="custom-block-profile-image-wrap mb-4">
                <img :src="BACK_URL + user.entreprise.logo" class="custom-block-profile-image img-fluid"
                  alt="africamoney" />
              </div>
              <div class="text-start">
                <div>
                  <h5>
                    <span class="badge" style="background-color: #006666">
                      <strong style="color: #ffffff">ENTREPRISE</strong> </span><br />
                    <a class="ms-2" style="font-size: 1rem; color: #000000">
                      {{ user.entreprise.entreprise }}</a>
                  </h5>
                </div>
                <h5>
                  <span class="badge" style="background-color: #006666">
                    <strong style="color: #ffffff">E-mail</strong> </span><br />
                  <a class="ms-2" style="
                      font-size: 1rem;
                      text-transform: lowercase;
                      color: #000000;
                    ">
                    {{ user.email }}</a>
                </h5>
                <h5>
                  <span class="badge" style="background-color: #006666">
                    <strong style="color: #ffffff">Téléphone</strong> </span><br />
                  <a class="ms-2" style="
                      font-size: 1rem;
                      text-transform: lowercase;
                      color: #000000;
                    ">
                    {{ user.entreprise.telephone }}</a>
                </h5>

                <h5>
                  <span class="badge" style="background-color: #006666">
                    <strong style="color: #ffffff">Adresse</strong>
                  </span>
                  <br />
                  <a class="ms-2" style="
                      font-size: 1rem;
                      text-transform: lowercase;
                      color: #000000;
                    ">
                    {{ user.entreprise.adresse }}</a>
                </h5>
              </div>
            </div>

            <!--     <div class="custom-block custom-block-bottom d-flex flex-wrap">
                            <div class="custom-block-bottom-item">
                                <a href="#" class="d-flex flex-column" @click="downloadProspectus">
                                    <i class="custom-block-icon bi-book"></i>
                                    <small>Prospectus</small>
                                </a>
                            </div>
                        </div> Icône de corbeille -->

            <div class="custom-block custom-block-bottom d-flex flex-wrap">
              <div class="custom-block-bottom-item">
                <a href="#" class="d-flex flex-column" @click="copyPaymentLink">
                  <i class="custom-block-icon bi-clipboard"></i>
                  <small>Page de paiements</small>
                </a>
              </div>
            </div>

            <div class="custom-block custom-block-bottom d-flex flex-wrap">
              <div class="custom-block-bottom-item">
                <a href="#" class="d-flex flex-column" @click.prevent="openPaymentLinkModal">
                  <i class="custom-block-icon bi-credit-card"></i>
                  <small>Lien de paiements</small>
                </a>
              </div>
            </div>



          </div>
        </div>

        <Footer />
      </main>
    </div>
  </div>


  <!-- Modal Payment Link -->
  <div class="modal fade" id="paymentLinkModal" tabindex="-1" aria-labelledby="paymentLinkModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header" style="background-color: #006666;">
          <h1 class="modal-title fs-5 text-center" id="paymentLinkModalLabel" style="color: white;">
            Lien de Paiement
          </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <form @submit.prevent="submitPaymentLinkForm">
            <!-- Champ Objet -->
            <div class="mb-3">
              <label for="objet" class="col-form-label">Objet:</label>
              <input type="text" class="form-control" id="objet" v-model="paymentLinkData.objet" required>
            </div>

            <!-- Champ Montant Minimum -->
            <div class="mb-3">
              <label for="montant_minimum" class="col-form-label">Montant Minimum:</label>
              <input type="number" class="form-control" id="montant_minimum" v-model="paymentLinkData.montant_minimum"
                required>
            </div>

            <!-- Champ Numéro -->
            <div class="mb-3">
              <label for="numero" class="col-form-label">Numéro:</label>
              <input type="text" class="form-control" id="numero" v-model="paymentLinkData.numero" required>
            </div>

            <!-- Champ Réponse -->
            <div class="mb-3">
              <label for="reponse" class="col-form-label">Réponse:</label>
              <input type="text" class="form-control" id="reponse" v-model="paymentLinkData.reponse" required>
            </div>

            <!-- Champ Message de Bienvenue -->
            <div class="mb-3">
              <label for="message_bienvenue" class="col-form-label">Message de Bienvenue:</label>
              <textarea class="form-control" id="message_bienvenue" v-model="paymentLinkData.message_bienvenue" rows="3"
                required></textarea>
            </div>

            <!-- Champ Réponse SIM -->
            <div class="mb-3">
              <label for="reponse_sim" class="col-form-label">Réponse SIM:</label>
              <input type="text" class="form-control" id="reponse_sim" v-model="paymentLinkData.reponse_sim" required>
            </div>

            <!-- Champ Statut -->
            <div class="mb-3">
              <label for="statut" class="col-form-label">Statut:</label>
              <select class="form-select" id="statut" v-model="paymentLinkData.statut" required>
                <option value="" disabled selected hidden>Sélectionnez le statut</option>
                <option value="actif">Actif</option>
                <option value="inactif">Inactif</option>
                <option value="en_attente">En attente</option>
                <option value="rejeté">Rejeté</option>
              </select>
            </div>

            <button type="submit" class="btn btn-success">Enregistrer</button>
          </form>
        </div>
      </div>
    </div>
  </div>


</template>

<style scoped>
.qr-scanner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.qr-scanner-overlay button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
</style>
