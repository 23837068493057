<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export default {
    components: {
        Header,
        SideBar
    },
    data() {
        return {
            transactions: [],
            loading: false,
            currentPage: 1,
            totalPages: 1,
            perPage: 15,
            clientId: "",
            startDate: "",
            endDate: "",
            selectedOperateur: "",
            selectedStatut: "",
        };
    },
    mounted() {
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
            try {
                const userData = JSON.parse(userDataString);
                this.clientId = userData.additional_info.id;
                this.loadTransactions();
            } catch (error) {
                console.error(
                    "Erreur lors du parsing de userData depuis sessionStorage:",
                    error
                );
            }
        }
    },
    watch: {
        // Watchers pour déclencher la recherche automatiquement lorsque les filtres changent
        startDate() {
            this.loadTransactions();
        },
        endDate() {
            this.loadTransactions();
        },
        selectedOperateur() {
            this.loadTransactions();
        },
        selectedStatut() {
            this.loadTransactions();
        },
    },
    methods: {
        async loadTransactions(page = 1) {
            this.loading = true;
            try {
                if (this.clientId) {
                    const params = {
                        page,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        operateur: this.selectedOperateur,
                        statut: this.selectedStatut,
                    };

                    const response = await axios.get(`/transactions/client/${this.clientId}`, {params }
                    );
                    this.transactions = response.data.transactions.data;
                    this.currentPage = response.data.transactions.current_page;
                    this.totalPages = response.data.transactions.last_page;
                } else {
                    Swal.fire("Erreur", "Client ID introuvable", "error");
                }
            } catch (error) {
                console.error("Erreur lors du chargement des transactions:", error);
            } finally {
                this.loading = false;
            }
        },
        goToPage(page) {
            if (page > 0 && page <= this.totalPages) {
                this.loadTransactions(page);
            }
        },
        exportToCSV() {
            Swal.fire({
                title: "Confirmer l'exportation",
                text: "Êtes-vous sûr de vouloir exporter ces données en CSV ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Exporter",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if (result.isConfirmed) {
                    const header = ["N°", "DATE", "EXPEDITEUR", "MONTANT", "GSM"].join(
                        ","
                    );
                    const rows = this.transactions.map((transaction, index) => {
                        return [
                            index + 1,
                            moment(transaction.created_at).format("DD/MM/YYYY"),
                            transaction.nomdestinataire,
                            transaction.montant,
                            transaction.operateur,
                        ].join(",");
                    });
                    const csvContent = [header, ...rows].join("\n");

                    const blob = new Blob([csvContent], {
                        type: "text/csv;charset=utf-8;",
                    });
                    const link = document.createElement("a");
                    if (link.download !== undefined) {
                        const url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", "transactions.csv");
                        link.style.visibility = "hidden";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }

                    Swal.fire({
                        title: "Succès !",
                        text: "L'exportation en CSV a réussi.",
                        icon: "success",
                    });
                }
            });
        },
        exportToPDF() {
            Swal.fire({
                title: "Exportation PDF",
                text: "Êtes-vous sûr de vouloir exporter ces données en PDF ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Exporter",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if (result.isConfirmed) {
                    const doc = new jsPDF();
                    let totalPages = 1;

                    // Ajouter l'en-tête du document
                    doc.setFillColor(0, 102, 102);
                    doc.rect(0, 0, doc.internal.pageSize.width, 40, "F");
                    const imgDataLeft = "../images/LOGOAFRICAMONEY.png";
                    doc.addImage(imgDataLeft, "JPEG", 25, 10, 23, 23);
                    const middleX = doc.internal.pageSize.width / 2;
                    const middleY = 17.5;
                    const text = "RELEVE DES TRANSACTIONS";
                    doc.setTextColor("#ffffff");
                    doc.text(text, middleX, middleY, {
                        align: "center"
                    });

                    // Ajouter la période si disponible
                    if (this.startDate && this.endDate) {
                        const formattedStartDate = moment(this.startDate).format(
                            "DD/MM/YYYY"
                        );
                        const formattedEndDate = moment(this.endDate).format("DD/MM/YYYY");
                        const periodText = `PÉRIODE: du ${formattedStartDate} au ${formattedEndDate}`;
                        const periodTextY = middleY + 10;
                        doc.text(periodText, middleX, periodTextY, {
                            align: "center",
                            color: "#ffffff",
                        });
                    }
                    doc.setTextColor(0, 0, 0);

                    // Préparer les données du tableau
                    const tableData = this.transactions.map((transaction, index) => [
                        index + 1,
                        moment(transaction.created_at).format("DD/MM/YYYY"),
                        transaction.nomdestinataire,
                        transaction.montant,
                        transaction.operateur,
                    ]);

                    const headers = ["N°", "DATE", "EXPEDITEUR", "MONTANT", "GSM"];

                    // Ajouter le tableau au PDF
                    doc.autoTable({
                        head: [headers],
                        body: tableData,
                        headStyles: {
                            fillColor: [0, 102, 102],
                            textColor: "#ffffff",
                            fontSize: 8,
                        },
                        bodyStyles: {
                            fontSize: 7
                        },
                        startY: middleY + 30,
                        didDrawPage: function (data) {
                            totalPages = doc.internal.getNumberOfPages();
                            const bottomMargin = 10;
                            doc.setFontSize(6);
                            doc.text(
                                "Page " + data.pageNumber + " sur " + totalPages,
                                14,
                                doc.internal.pageSize.height - bottomMargin
                            );
                            const currentDate = new Date();
                            const dateString = currentDate.toLocaleDateString();
                            const timeString = currentDate.toLocaleTimeString();
                            doc.text(
                                "imprimé le: " + dateString + " à " + timeString,
                                14,
                                doc.internal.pageSize.height - bottomMargin + 5
                            );
                        },
                    });

                    doc.save("releve_des_transactions.pdf");

                    Swal.fire({
                        title: "Succès !",
                        text: "L'exportation en PDF a réussi.",
                        icon: "success",
                    });
                }
            });
        },
    },
};
</script>
<template>
<div>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="row">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                          <h5 class="mb-4">RECHERCHER</h5>
                          <hr> <!-- Horizontal line added here -->
                            <div class="row mb-4">
                                <div class="col-md-3">
                                    <label for="startDate">Date de début</label>
                                    <input type="date" id="startDate" class="form-control" v-model="startDate" />
                                </div>
                                <div class="col-md-3">
                                    <label for="endDate">Date de fin</label>
                                    <input type="date" id="endDate" class="form-control" v-model="endDate" />
                                </div>
                                <div class="col-md-3">
                                    <label for="operateur">Opérateur</label>
                                    <select id="operateur" class="form-control" v-model="selectedOperateur">
                                        <option value="">Tous</option>
                                        <option value="MTN">MTN</option>
                                        <option value="MOOV">MOOV</option>
                                        <option value="CELTIS">CELTIS</option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="statut">Statut</label>
                                    <select id="statut" class="form-control" v-model="selectedStatut">
                                        <option value="">Tous</option>
                                        <option value="Succès">Succès</option>
                                        <option value="Échec">Échec</option>
                                        <option value="En attente">En attente</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row my-1">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">GERER MES DEPOTS</h5>
                            <hr> <!-- Horizontal line added here -->
                            <button @click="exportToCSV" class="btn btn-sm" style="background-color: #006666; color: white;">
                                <i class="bi bi-file-earmark-spreadsheet"></i> Imprimer le relevé (CSV)
                            </button>
                            <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                            <button @click="exportToPDF" class="btn btn-sm" style="background-color: #006666; color: white;">
                                <i class="bi bi-file-earmark-pdf"></i> Imprimer le relevé (PDF)
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row my-1">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">MES TRANSACTIONS</h5>
                            <hr> <!-- Horizontal line added here -->
                            <div class="table-responsive">
                                <table class="account-table table">
                                    <thead style="background-color: #006666">
                                        <tr>
                                            <th scope="col" style="color: white">N°</th>
                                            <th scope="col" style="color: white">DATE</th>
                                            <th scope="col" style="color: white">EXPÉDITEUR</th>
                                            <th scope="col" style="color: white">MONTANT</th>
                                            <th scope="col" style="color: white">GSM</th>
                                            <th scope="col" style="color: white">STATUT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- Afficher le spinner pendant le chargement -->
                                        <tr v-if="loading">
                                            <td colspan="6" class="text-center">
                                                <div class="spinner-border text-success" role="status">
                                                    <span class="sr-only">Chargement en cours...</span>
                                                </div>
                                                <p>Chargement en cours...</p>
                                            </td>
                                        </tr>

                                        <!-- Afficher un message si aucune donnée n'est disponible -->
                                        <tr v-if="!loading && transactions.length === 0">
                                            <td colspan="6" class="text-center">
                                                <div class="alert alert-warning d-flex justify-content-center align-items-center" role="alert">
                                                    Aucune donnée disponible.
                                                </div>
                                            </td>
                                        </tr>

                                        <!-- Afficher les transactions lorsqu'elles sont disponibles -->
                                        <tr v-for="(transaction, index) in transactions" :key="transaction.id">
                                            <td>{{ index + 1 }}</td>
                                            <td>
                                                {{
                            new Date(
                              transaction.created_at
                            ).toLocaleDateString()
                          }}
                                            </td>
                                            <td>{{ transaction.nomdestinataire }}</td>
                                            <td>{{ transaction.montant }}</td>

                                            <td>
                                                <img v-if="transaction.operateur === 'MTN'" src="/../../images/MOMOPAY.png" alt="MTN" style="width: 35px; height: 34px" />
                                                <img v-else-if="transaction.operateur === 'MOOV'" src="/../../images/MOOVFLOOZ.png" alt="MOOV" style="width: 35px; height: 34px" />
                                                <img v-else-if="transaction.operateur === 'CELTIIS'" src="/../../images/LOGOCELTIS.png" alt="CELTIIS" style="width: 35px; height: 34px" />
                                                <span v-else>{{ transaction.operateur }}</span>
                                            </td>
                                            <td>
                                                <span v-if="transaction.status === 'PENDING'" class="badge text-bg-warning">
                                                    {{ transaction.status }}
                                                </span>
                                                <span v-else-if="transaction.status === 'SUCCESSFUL'" class="badge text-bg-success">
                                                    {{ transaction.status }}
                                                </span>
                                                <span v-else-if="transaction.status === 'FAILED'" class="badge text-bg-danger">
                                                    {{ transaction.status }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation">
                                <ul class="pagination">
                                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                        <a class="page-link" @click="goToPage(currentPage - 1)">Précédent</a>
                                    </li>
                                    <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: page === currentPage }">
                                        <a class="page-link" @click="goToPage(page)">{{
                        page
                      }}</a>
                                    </li>
                                    <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                        <a class="page-link" @click="goToPage(currentPage + 1)">Suivant</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</div>
</template>

<style></style>
