import { createRouter, createWebHistory } from "vue-router";
import Swal from "sweetalert2";
import Acceuil from "../views/Acceuil.vue";
import PaymentLink from "../views/PaymentLink.vue";
import Client from "../views/entreprise/Client.vue";
import Apercu from "../views/entreprise/Apercu.vue";
import DetailsTransaction from "../views/entreprise/DetailsTransaction.vue";
import Dossier from "../views/entreprise/Dossier.vue";
import NumeroPaie from "../views/entreprise/NumeroPaie.vue";
import Profile from "../views/entreprise/Profile.vue";
import Transaction from "../views/entreprise/Transaction.vue";
import Mesgestionnaire from "../views/entreprise/Mesgestionnaire.vue";
import Gerer from "../views/entreprise/Gerer.vue";
import Caise from "../views/entreprise/Caise.vue";
import Test from "../views/Test.vue";
import Inscription from "../views/Inscription.vue";
import MotdepasseOublier from "../views/MotdepasseOublier.vue";
import ListeMarchand from "../views/ListeMarchand.vue";
import PayQr from "../views/PayQr.vue";
import PayEntreprise from "../views/PayEntreprise.vue";
import RecuDePaiement from "../views/RecuDePaiement.vue";
import Connexion from "../views/Connexion.vue";
import Cont from "../views/Cont.vue";
import Verification from "../views/Verification.vue";
import InscriptionPayLinkUser from "../views/InscriptionPayLinkUser.vue";
import EEngagement from "../views/EEngagement.vue";
import Paiements from "../views/Paiements.vue";
import PayLinks from "../views/PayLinks.vue";
import PayLinkDetails from "../views/PayLinkDetails.vue";
import Sengager from "../views/Sengager.vue";

import DossierTransaction from "@/views/entreprise/DossierTransaction.vue";
import Expediteur from "@/views/entreprise/Expediteur.vue";
import BonusTransaction from "@/views/entreprise/BonusTransaction.vue";
import ReseauGsm from "@/views/entreprise/ReseauGsm.vue";
import DossierDetails from "@/views/entreprise/DossierDetails.vue";
import DebiteurDetails from "@/views/entreprise/DebiteurDetails.vue";
import Suivie from "@/views/entreprise/Suivie.vue";
import DossierTransac from "@/views/DossierTransac.vue";
import ExportTransaction from "@/views/entreprise/ExportTransaction.vue";
import Pointage from "@/views/entreprise/Pointage.vue";
import ListesOuvriers from "@/views/entreprise/ListesOuvriers.vue";
import ListesOuvriersPointes from "@/views/entreprise/ListesOuvriersPointes.vue";
// ROUTE DE L'ADMINISTRATEUR
import Admin from "@/views/admin/Admin.vue";
import AjouteEntreprise from "@/views/admin/AjouteEntreprise.vue";
import MesEntreprise from "@/views/admin/MesEntreprise.vue";
import Entite from "@/views/admin/Entite.vue";
import ServiceClient from "@/views/admin/ServiceClient.vue";
import StatPayout from "@/views/admin/StatPayout.vue";

// route client

import Dashboard from "@/views/client/Dashboard.vue";
import MesPayLinks from "@/views/client/MesPayLinks.vue";
import MesPayOuts from "@/views/client/MesPayOuts.vue";
import MesTrxs from "@/views/client/MesTrxs.vue";
import Statistique from "@/views/client/Statistique.vue";
import StatPayLinks from "@/views/client/StatPayLinks.vue";
import AddPayLinks from "@/views/client/AddPayLinks.vue";
import PayLinksTransactions from "@/views/client/PayLinksTransactions.vue";
import Profil from "@/views/client/Profil.vue";
import Parametre from "@/views/client/Parametre.vue";
import Tutorial from "@/views/client/Tutorial.vue";
import MesContacts from "@/views/client/MesContacts.vue";
import Rapports from "@/views/client/Rapports.vue";

// route service-client

import MonEspace from "@/views/service-client/MonEspace.vue";
import PayoutValidation from "@/views/service-client/PayoutValidation.vue";
import AddTutorial from "@/views/service-client/AddTutorial.vue";
import AlertEngagement from "@/views/service-client/AlertEngagement.vue";
import SavDashboard from "@/views/service-client/SavDashboard.vue";

const routes = [
  {
    path: "/",
    name: "acceuil",
    component: Acceuil,
  },

  {
    path: "/paiements",
    name: "paiements",
    component: Paiements,
  },

  {
    path: "/verification",
    name: "verification",
    component: Verification,
  },

  {
    path: "/paymentlink/:id",
    name: "paymentlink",
    component: PaymentLink,
  },

  {
    path: "/mot-de-passe-oublier",
    name: "mot-de-passe-oublier",
    component: MotdepasseOublier,
  },

  {
    path: "/dossier-transac",
    name: "dossier-transac",
    component: DossierTransac,
  },

  {
    path: "/test",
    name: "test",
    component: Test,
  },

  {
    path: "/connexion",
    name: "connexion",
    component: Connexion,
  },

  {
    path: "/inscription",
    name: "inscription",
    component: Inscription,
  },

  {
    path: "/listes-marchands",
    name: "listes-marchands",
    component: ListeMarchand,
  },

  {
    path: "/pay/:id",
    name: "pay",
    component: PayEntreprise,
  },

  {
    path: "/payqr",
    name: "payqr",
    component: PayQr,
  },

  {
    path: "/recu-paiement",
    name: "recu-paiement",
    component: RecuDePaiement,
  },

  {
    path: "/paylink-user-registration",
    name: "paylink-user-registration",
    component: InscriptionPayLinkUser,
  },

  {
    path: "/e-engagement",
    name: "e-engagement",
    component: EEngagement,
  },

  {
    path: "/paylinks",
    name: "paylinks",
    component: PayLinks,
  },

  {
    path: "/sengager",
    name: "sengager",
    component: Sengager,
  },

  //ROUTE POUR LES ENTREPRISES

  {
    path: "/entreprise/client",
    name: "client",
    component: Client,
  },

  {
    path: "/entreprise/detailstransaction",
    name: "detailstransaction",
    component: DetailsTransaction,
  },

  {
    path: "/entreprise/dossier",
    name: "dossier",
    component: Dossier,
  },

  {
    path: "/entreprise/dossier-transaction",
    name: "dossier-transaction",
    component: DossierTransaction,
  },

  {
    path: "/entreprise/expediteur-transaction",
    name: "expediteur-transaction",
    component: Expediteur,
  },

  {
    path: "/entreprise/bonus-transaction",
    name: "bonus-transaction",
    component: BonusTransaction,
  },

  {
    path: "/entreprise/reseau-gsm",
    name: "reseau-gsm",
    component: ReseauGsm,
  },

  {
    path: "/entreprise/numeropaie",
    name: "numeropaie",
    component: NumeroPaie,
  },

  {
    path: "/entreprise/profile/:id",
    name: "profile",
    component: Profile,
  },

  {
    path: "/entreprise/transaction",
    name: "transaction",
    component: Transaction,
  },

  {
    path: "/entreprise/apercu",
    name: "apercu",
    component: Apercu,
  },

  {
    path: "/entreprise/dossier-details/:id",
    name: "dossier-details",
    component: DossierDetails,
  },

  {
    path: "/entreprise/debiteur-details/:id",
    name: "debiteur-details",
    component: DebiteurDetails,
  },

  {
    path: "/entreprise/mesgestionnaire",
    name: "mesgestionnaire",
    component: Mesgestionnaire,
  },

  {
    path: "/entreprise/gerer",
    name: "gerer",
    component: Gerer,
  },

  {
    path: "/entreprise/suivi",
    name: "suivi",
    component: Suivie,
  },

  {
    path: "/entreprise/caise",
    name: "caise",
    component: Caise,
  },

  {
    path: "/entreprise/export-transaction",
    name: "export-transaction",
    component: ExportTransaction,
  },

  {
    path: "/entreprise/pointage",
    name: "pointage",
    component: Pointage,
  },

  {
    path: "/entreprise/listes-ouvriers",
    name: "listes-ouvriers",
    component: ListesOuvriers,
  },

  {
    path: "/entreprise/ouvriers-pointes",
    name: "ouvriers-pointes",
    component: ListesOuvriersPointes,
  },

  // ROUTE POUR LES CLIENTS

  {
    path: "/client/dashboard",
    name: "dashboard",
    component: Dashboard,
  },

  {
    path: "/client/mes-paylinks",
    name: "mes-paylinks",
    component: MesPayLinks,
  },

  {
    path: "/client/mes-retraits",
    name: "mes-payouts",
    component: MesPayOuts,
  },

  {
    path: "/client/ajouter-paylink",
    name: "ajouter-paylink",
    component: AddPayLinks,
  },

  {
    path: "/client/mes-transactions",
    name: "mes-transactions",
    component: MesTrxs,
  },

  {
    path: "/client/paylink-transactions/:id",
    name: "paylink-transactions",
    component: PayLinksTransactions,
  },

  {
    path: "/client/statistique",
    name: "statistique",
    component: Statistique,
  },

  {
    path: "/client/statistique-paylinks",
    name: "statistique-paylinks",
    component: StatPayLinks,
  },

  {
    path: "/client/parametre",
    name: "parametre",
    component: Parametre,
  },

  {
    path: "/client/tutorial",
    name: "tutorial",
    component: Tutorial,
  },

  {
    path: "/client/profil",
    name: "profil",
    component: Profil,
  },

  {
    path: "/client/mes-contacts",
    name: "mes-contacts",
    component: MesContacts,
  },

  {
    path: "/client/rapports",
    name: "rapport",
    component: Rapports,
  },

  //ROUTE POUR LE SERVICE CLIENT

  {
    path: "/service-client/mon-espace",
    name: "mon-espace",
    component: MonEspace,
  },

  {
    path: "/service-client/payout-validation",
    name: "payout-validation",
    component: PayoutValidation,
  },

  {
    path: "/service-client/add-tutorial",
    name: "add-tutorial",
    component: AddTutorial,
  },

  {
    path: "/service-client/alert-engagement",
    name: "alert-engagement",
    component: AlertEngagement,
  },

  {
    path: "/service-client/savdashboard",
    name: "savdashboard",
    component: SavDashboard,
  },

  // ROUTE POUR L'ADMINISTRATEUR

  {
    path: "/admin/admin",
    name: "admin",
    component: Admin,
  },

  {
    path: "/admin/ajouter-entreprise",
    name: "ajouter-entreprise",
    component: AjouteEntreprise,
  },

  {
    path: "/admin/mes-entreprise",
    name: "mes-entreprise",
    component: MesEntreprise,
  },

  {
    path: "/admin/mes-entites",
    name: "mes-entites",
    component: Entite,
  },

  {
    path: "/admin/mes-service-clients",
    name: "mes-service-clients",
    component: ServiceClient,
  },

  {
    path: "/admin/stat-payouts-deposits",
    name: "stat-payouts-deposits",
    component: StatPayout,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "acceuil",
    "connexion",
    "inscription",
    "verification",
    "mot-de-passe-oublier",
    "listes-marchands",
    "paymentlink",
    "payqr",
    "pay",
    "paiements",
    "recu-paiement",
    "paylink-user-registration",
    "e-engagement",
    "paylinks",
    "sengager",
  ];
  const isPublicPage = publicPages.includes(to.name);
  const token = sessionStorage.getItem("authToken");

  if (!isPublicPage && !token) {
    Swal.fire({
      icon: "warning",
      title: "Vous devez d'abord vous connecter",
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      customClass: {
        toast: "swal2-toast",
      },
    });
    next("/connexion");
  } else {
    next();
  }
});

export default router;
