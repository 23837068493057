<template>
    <Header />

    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="title-group mb-3">
                    <h1 class="h2 mb-0">Paramètres</h1>
                </div>

                <div class="row my-4">
                    <div class="col-lg-7 col-12">
                        <div class="custom-block bg-white">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="profile-tab" data-bs-toggle="tab"
                                        data-bs-target="#profile-tab-pane" type="button" role="tab"
                                        aria-controls="profile-tab-pane" aria-selected="true">
                                        Profil
                                    </button>
                                </li>

                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="password-tab" data-bs-toggle="tab"
                                        data-bs-target="#password-tab-pane" type="button" role="tab"
                                        aria-controls="password-tab-pane" aria-selected="false">
                                        Mot de passe
                                    </button>
                                </li>
                            </ul>

                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="profile-tab-pane" role="tabpanel"
                                    aria-labelledby="profile-tab" tabindex="0">
                                    <h6 class="mb-4"> Modifiez vos informations</h6>

                                    <form class="custom-form profile-form" role="form">
                                        <input class="form-control" type="text" placeholder="Nom et Prénom" />
                                        <input class="form-control" type="tel" placeholder="Télephone" />
                                        <input class="form-control" type="text" placeholder="Adresse" />
                                        <input class="form-control" type="file" accept="image/*" />
                                        <div class="d-flex">
                                            <button type="submit" class="form-control ms-2">Mettre à jour</button>
                                        </div>
                                    </form>
                                </div>

                                <div class="tab-pane fade" id="password-tab-pane" role="tabpanel"
                                    aria-labelledby="password-tab" tabindex="0">
                                    <h6 class="mb-4">Modifier le mot de passe</h6>
                                    <form class="custom-form profile-form" @submit.prevent="updatePassword" role="form">
                                        <input class="form-control" type="password" v-model="oldPassword"
                                            placeholder="Ancien mot de passe" />
                                        <input class="form-control" type="password" v-model="newPassword"
                                            placeholder="Nouveau mot de passe" />

                                        <button type="submit" class="form-control ms-2" :disabled="loading">
                                            <span v-if="loading" class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                            <span v-if="loading"> En cours...</span>
                                            <span v-else>Mettre à jour le mot de passe</span>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-5 col-12">
                        <div class="custom-block custom-block-contact">
                            <h6 class="mb-4">
                                Vous ne trouvez toujours pas ce que vous cherchez ?
                            </h6>
                            <p>
                                <strong>Appelez-nous :</strong>
                                <a href="tel: 97000000" class="ms-2">
                                    (+229) 97000000
                                </a>
                            </p>
                            <a href="#" class="btn custom-btn custom-btn-bg-white mt-3">
                                Discutez avec nous
                            </a>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';

export default {
    components: { Header, SideBar },
    data() {
        return {
            oldPassword: '',
            newPassword: '',
            loading: false,
        };
    },
    methods: {
        updatePassword() {
            this.loading = true;

            const payload = {
                old_password: this.oldPassword,
                new_password: this.newPassword
            };

            const userData = JSON.parse(sessionStorage.getItem('userData'));
            const UserId = userData.id;

            axios.put(`/users/${UserId}/update-password`, payload)
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Mot de passe mis à jour !',
                        showConfirmButton: false,
                        timer: 1500
                    });

                    this.oldPassword = '';
                    this.newPassword = '';
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    }
};
</script>
