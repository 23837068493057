<template>
    <div class="container">
      <header class="navbar sticky-top flex-md-nowrap bg-dark">
        <div class="col-md-3 col-lg-3 me-0 px-3 fs-6">
          <a class="navbar-brand" href="/">
            <h6 class="text-white text-center">Détails de l'entreprise</h6>
          </a>
        </div>
      </header>
  
      <main class="main">
        <Head /><br />

      </main>
  

  
      <br /><br />
  
      <footer class="py-4 mt-auto" style="background-color: #006666; color: white;">
    <div class="container">
        <div class="row align-items-center justify-content-center justify-content-sm-between text-center text-sm-start">
            <div class="col-auto">
                <div class="small m-0" v-text="'© Africamoney ' + new Date().getFullYear()"></div>
            </div>
            <div class="col-auto">
                <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Confidentialité</a>
                <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Terms et conditions</a>
            </div>
        </div>
    </div>
</footer>

    </div>
  </template>
  
  <script>
  import axios from "@/axs/axios.js";
  import Head from "@/components/Head.vue";

  
  export default {
    components: { Head },

  };
  </script>
  
  <style scoped>
  /* Ajoutez vos styles ici */
  </style>
  