<template>

    <Head /> <br /><br /><br /><br />
    <section class="pt-0">
        <div class="container">
            <div class="row g-xl-7 justify-content-center">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <div>
                            <div class="mb-3">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <input type="text" class="form-control mb-2 mb-md-0" placeholder="ENTREPRISE"
                                            v-model="filters.entreprise" @input="fetchEntreprises">
                                    </div>
                                    <div class="col-lg-6">
                                        <input type="text" class="form-control mb-2 mb-md-0" placeholder="NUMERO"
                                            v-model="filters.marchandmtn" @input="fetchEntreprises">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="entreprises.length === 0" class="text-center">
                            <p>Aucune entreprise trouvée</p>
                        </div>
                        <div v-else>
                            <div v-for="entreprise in entreprises" :key="entreprise.id">
                                <article class="card card-hover-shadow border p-2 mb-4">
                                    <div class="row">
                                        <div class="col-md-7 d-flex">
                                            <img :src="BACK_URL + entreprise.logo" class="img-fluid card-img"
                                                alt="entreprise-logo" style="width: 150px; height: 100px;">
                                            <p class="card-title" style="margin-left: 66px; margin-top: 32px;"></p>
                                            <p>{{ entreprise.entreprise }}</p>

                                        </div>
                                        <div class="col-md-5">
                                            <div class="card-body d-flex flex-column h-100 ps-0 pe-3">
                                                <div></div>
                                                <div class="d-sm-flex justify-content-between align-items-center mt-auto"
                                                    style="margin-left: auto; margin-bottom: 10px;">
                                                    <a class="icon-link icon-link-hover stretched-link badge text-bg-danger"
                                                        :href="'/pay/' + entreprise.id" style="padding: 13px;"
                                                        data-bs-target="#paymentModal">ENVOYEZ</a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                        <button v-if="pagination.prev_page_url"
                            @click="fetchEntreprises(pagination.current_page - 1)">Previous</button>
                        <button v-if="pagination.next_page_url"
                            @click="fetchEntreprises(pagination.current_page + 1)">Next</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="paymentModal" tabindex="-1" aria-labelledby="paymentModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header" style="background-color: #006666;">
                        <h1 class="modal-title fs-5 text-center" id="paymentModalLabel" style="color: white;">Paiement
                        </h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div class="modal-body">
                        <form @submit.prevent="handlePayment">
                            <div class="mb-3">
                                <label for="numero1" class="col-form-label">MTN:</label>
                                <input type="text" class="form-control" id="numero1" v-model="paymentData.numero1"
                                    required>
                            </div>
                            <div class="mb-3">
                                <label for="numero2" class="col-form-label">MOOV:</label>
                                <input type="text" class="form-control" id="numero2" v-model="paymentData.numero2"
                                    required>
                            </div>
                            <div class="mb-3">
                                <label for="numero3" class="col-form-label">CELTIIS:</label>
                                <input type="text" class="form-control" id="numero3" v-model="paymentData.numero3"
                                    required>
                            </div>
                            <div class="d-flex justify-content-end">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <br /><br />
    <footer class="py-4 mt-auto" style="background-color: #006666; color: white;">
    <div class="container">
        <div class="row align-items-center justify-content-center justify-content-sm-between text-center text-sm-start">
            <div class="col-auto">
                <div class="small m-0" v-text="'© Africamoney ' + new Date().getFullYear()"></div>
            </div>
            <div class="col-auto">
                <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Confidentialité</a>
                <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Terms et conditions</a>
            </div>
        </div>
    </div>
</footer>

</template>

<script>
import axios from "@/axs/axios.js";
import { BACK_URL } from "@/entreprise_logo.js";
import Head from "@/components/Head.vue";

export default {
    components: { Head },
    data() {
        return {
            entreprises: [],
            pagination: {},
            filters: {
                entreprise: '',
                marchandmtn: '',
            },
            BACK_URL: BACK_URL,
            paymentData: {
                numero1: '',
                numero2: '',
                numero3: '',
            },
        };
    },
    methods: {
        async fetchEntreprises(page = 1) {
            try {
                const response = await axios.get('/getallentreprises', {
                    params: {
                        page: page,
                        entreprise: this.filters.entreprise,
                        marchandmtn: this.filters.marchandmtn,
                    }
                });

                this.entreprises = response.data.data.data;
                this.pagination = {
                    current_page: response.data.data.current_page,
                    last_page: response.data.data.last_page,
                    next_page_url: response.data.data.next_page_url,
                    prev_page_url: response.data.data.prev_page_url,
                };
            } catch (error) {
                console.error("Erreur lors de la récupération des entreprises :", error);
            }
        },
        showModal(entreprise) {
            this.paymentData = {
                numero1: '',
                numero2: '',
                numero3: '',
            };
        },
        handlePayment() {
            // Implémentez la logique de paiement ici
            console.log(this.paymentData);
            // Fermez le modal après le paiement
            const modal = bootstrap.Modal.getInstance(document.getElementById('paymentModal'));
            modal.hide();
        }
    },
    mounted() {
        this.fetchEntreprises();
    }
};
</script>

<style></style>
