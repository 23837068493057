<template>

  <main class="main">
      <Head />
    <!-- Section 1: Hero -->

    <br/>
    <section class="hero bg-dark text-white text-center py-5">
      <div class="container">
        <h1 class="display-4 fw-bold">Recevez des fonds facilement avec un PayLink</h1>
        <p class="lead">Simplifiez vos collectes pour les mariages, anniversaires, et autres événements grâce à des liens de paiement personnalisés.</p>
         <a href="/paylink-user-registration" class="btn btn-lg rounded-pill mt-4" style="background-color: #006666; color: white;">Commencer maintenant</a>

      </div>
    </section>

    <!-- Section 2: Comment ça marche -->
    <section class="how-it-works py-5 bg-light">
      <div class="container">
        <div class="row text-center mb-5">
          <h2 class="fw-bold">Comment ça marche ?</h2>
          <p class="text-muted">Avec un PayLink, la collecte de fonds n’a jamais été aussi simple et flexible.</p>
        </div>
        <div class="row text-center">
          <div class="col-md-4">
            <img src="https://via.placeholder.com/150" class="img-fluid mb-3" alt="Créer un lien">
            <h5>Créez votre lien personnalisé</h5>
            <p>Configurez un lien de paiement pour un événement en quelques clics.</p>
          </div>
          <div class="col-md-4">
            <img src="https://via.placeholder.com/150" class="img-fluid mb-3" alt="Partager le lien">
            <h5>Partagez-le avec vos proches</h5>
            <p>Envoyez votre lien PayLink via WhatsApp, email ou sur les réseaux sociaux.</p>
          </div>
          <div class="col-md-4">
            <img src="https://via.placeholder.com/150" class="img-fluid mb-3" alt="Recevez vos fonds">
            <h5>Recevez vos fonds instantanément</h5>
            <p>Suivez vos collectes et recevez des paiements directement sur votre compte.</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Section 3: Pourquoi choisir PayLinks -->
    <section class="why-paylinks py-5">
      <div class="container">
        <div class="row text-center mb-5">
          <h2 class="fw-bold">Pourquoi utiliser un PayLinks ?</h2>
        </div>
        <div class="row text-center">
          <div class="col-md-4">
            <i class="fas fa-lock fa-3x mb-3"></i>
            <h5>Sécurité maximale</h5>
            <p>PayLinks garantit des transactions sécurisées et rapides pour vos collectes.</p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-mobile-alt fa-3x mb-3"></i>
            <h5>Facile d'utilisation</h5>
            <p>Créez, partagez et gérez vos PayLinks depuis n'importe quel appareil.</p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-globe fa-3x mb-3"></i>
            <h5>Partout dans le monde</h5>
            <p>Recevez des fonds de n'importe où, à tout moment, en quelques secondes.</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Section 4: Appel à l'action -->
    <section class="cta py-5 text-white text-center" id="start">
      <div class="container">
        <h2 class="fw-bold">Prêt à recevoir des fonds pour votre événement ?</h2>
        <p>Créez votre premier lien de paiement pour collecter des fonds dès maintenant !</p>
       <a href="/paylink-user-registration" class="btn btn-light btn-lg rounded-pill mt-4" style="background-color: #006666; color: white;">Créer mon PayLink</a>
      </div>
    </section>
  </main>

  <footer class="py-4 mt-auto" style="background-color: #006666; color: white;">
    <div class="container">
      <div class="row align-items-center justify-content-center justify-content-sm-between text-center text-sm-start">
        <div class="col-auto">
          <div class="small m-0" v-text="'© Africamoney ' + new Date().getFullYear()"></div>
        </div>
        <div class="col-auto">
          <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Confidentialité</a>
          <a class="small mx-2" href="#!" style="color: white; text-decoration: none;">Terms et conditions</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Head from "@/components/Head.vue";
import Swal from "sweetalert2";
export default {
  components: { Head },
  data() {
    return {};
  },
  methods: {

  }
};
</script>

<style scoped>
.hero {
    background: black;
    color: white;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
