<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
    components: {
        Header,
        SideBar
    },
    data() {
        return {
            payoutRequests: [],
            currentPage: 1,
            totalPages: 1,
            isLoading: false,
            error: null,
            clientId: null
        };
    },
    mounted() {
        const userDataString = sessionStorage.getItem('userData');
        if (userDataString) {
            try {
                const userData = JSON.parse(userDataString);
                this.clientId = userData.additional_info.id;
                this.fetchPayoutRequests(this.currentPage);
            } catch (error) {
                console.error('Erreur lors du parsing de userData depuis sessionStorage:', error);
            }
        } else {
            console.error('userData n\'est pas disponible dans sessionStorage');
        }
    },
    methods: {
        async fetchPayoutRequests(page) {
            if (!this.clientId) {
                console.error('clientId non défini');
                return;
            }

            this.isLoading = true;
            try {
                const response = await axios.get(`/payout-request/${this.clientId}?page=${page}`);
                this.payoutRequests = response.data.payout_request.data;
                this.currentPage = response.data.payout_request.current_page;
                this.totalPages = response.data.payout_request.last_page;
                this.error = null;
            } catch (err) {
                this.error = 'Erreur lors du chargement des données.';
                Swal.fire('Erreur', this.error, 'error');
            } finally {
                this.isLoading = false;
            }
        },
        goToPage(page) {
            if (page > 0 && page <= this.totalPages) {
                this.fetchPayoutRequests(page);
            }
        }
    }
};
</script>

<template>
<div>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="row">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            
                          <h5 class="mb-4">RECHERCHER</h5>
                          <hr> <!-- Horizontal line added here -->
                            <div class="row mb-4">
                                <div class="col-md-4">
                                    <label for="startDate">Date de début</label>
                                    <input type="date" id="startDate" class="form-control">
                                </div>
                                <div class="col-md-4">
                                    <label for="endDate">Date de fin</label>
                                    <input type="date" id="endDate" class="form-control">
                                </div>
                                <div class="col-md-4">
                                    <label for="searchNumber">Numéro</label>
                                    <input type="text" class="form-control" id="searchNumber">
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>

                <div class="row my-1">
                    <div class="col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">GERER MES RETRAITS</h5>
                            <hr> <!-- Horizontal line added here -->
                            <div class="d-flex flex-wrap justify-content-start">
                                <button @click="NewPayout" class="btn btn-sm me-2 mb-2" style="background-color: #006666; color: white;">
                                    <i class="bi bi-person-plus"></i> Nouveau Paiement
                                </button>
                                <button type="button"  class="btn btn-sm me-2 mb-2" style="background-color: #006666; color: white;">
                                <i class="bi bi-plus-trash"></i> Suupprimer
                            </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row my-1">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">MES RETRAITS</h5>
                            <hr> <!-- Horizontal line added here -->
                            <div class="table-responsive">
                                <table class="account-table table">
                                    <thead style="background-color: #006666;">
                                        <tr>
                                            <th scope="col" style="color: white;">N°</th>
                                            <th scope="col" style="color: white;">DATE</th>
                                            <th scope="col" style="color: white;">MONTANT</th>
                                            <th scope="col" style="color: white;">GSM</th>
                                            <th scope="col" style="color: white;">STATUT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(request, index) in payoutRequests" :key="request.id">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ new Date(request.created_at).toLocaleDateString() }}</td>
                                            <td>{{ request.amount }}</td>
                                            <td>{{ request.withdraw_number }}</td>
                                            <td>
                                                <span v-if="request.status === 'PENDING'" class="badge rounded-pill text-bg-warning">En attente</span>
                                                <span v-if="request.status === 'SUCCESSFUL'" class="badge rounded-pill text-bg-success">Approuvé</span>
                                                <span v-if="request.status === 'FAILED'" class="badge rounded-pill text-bg-danger">Rejeté</span>
                                                <span v-if="request.status === 'EXPIRED'" class="badge rounded-pill text-bg-secondary">Expiré</span>
                                            </td>
                                        </tr>
                                        <tr v-if="payoutRequests.length === 0">
                                            <td colspan="5" class="text-center">Aucune donnée disponible</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <nav aria-label="Page navigation">
                                <ul class="pagination">
                                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                        <a class="page-link" href="#" @click.prevent="goToPage(currentPage - 1)">Précédent</a>
                                    </li>
                                    <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: currentPage === page }">
                                        <a class="page-link" href="#" @click.prevent="goToPage(page)">{{ page }}</a>
                                    </li>
                                    <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                        <a class="page-link" href="#" @click.prevent="goToPage(currentPage + 1)">Suivant</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</div>
</template>

<style></style>
