<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-#006666 py-3 fixed-top">
        <div class="container px-5">
            <a class="navbar-brand" href="/">
                <!-- Logo ajouté avant le texte "AfrikMoney" -->
                <img src="/images/LOGOAFRICAMONEY.png" alt="Logo" style="height: 30px; margin-right: 10px;">
                <h3 class="text-white d-inline-block">AfrikMoney</h3> <!-- 'd-inline-block' pour garder tout sur la même ligne -->
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 small fw-bolder">
                    <li v-if="!hasAuthToken" class="nav-item">
                        <a class="nav-link text-white fw-bold" href="/">Accueil</a>
                    </li>
                    <li v-if="!hasAuthToken" class="nav-item">
                        <a class="nav-link text-white fw-bold" href="/e-engagement">E-engagement</a>
                    </li>
                    <li v-if="!hasAuthToken" class="nav-item">
                        <a class="nav-link text-white fw-bold" href="#">Contactez-nous</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <br /><br />
</template>


<script>
import axios from "@/axs/axios.js";
import Swal from 'sweetalert2';
import { useRouter } from 'vue-router';

export default {
    computed: {
        hasAuthToken() {
            return !!sessionStorage.getItem('authToken');
        }
    },
    methods: {
        async logoutUser() {
            try {
                const response = await axios.post('/logout', null, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('authToken')}`,
                    },
                });

                if (response.status === 200) {
                    console.log('Déconnexion réussie.');
                    sessionStorage.removeItem('authToken');
                    this.$router.push('/').then(() => {
                        window.location.reload();
                    });
                } else {
                    console.error('Échec de la déconnexion. Réponse de l\'API:', response.data);
                }
            } catch (error) {
                console.error('Erreur lors de la requête de déconnexion:', error);
            }
        },
    }
}
</script>

<style></style>
