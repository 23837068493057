<template>
  <div>
    <Header />
    <div class="container-fluid">
      <div class="row">
        <SideBar />
        <main
          class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start"
        >
          <div class="row my-4">
            <div class="col-lg-12 col-12">
              <div class="custom-block bg-white">
                <h5 class="mb-4">Recherche</h5>
                <div class="row mb-12">
                  <div class="col-md-6">
                    <label for="date">Date (rappel)</label>
                    <input
                      type="date"
                      id="date"
                      class="form-control"
                      v-model="date"
                      @change="fetchEEngagements"
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="statut">Mode de rappel</label>
                    <select
                      id="statut"
                      class="form-control"
                      v-model="reminderMode"
                      @change="fetchEEngagements"
                    >
                      <option value="">Tous</option>
                      <option value="whatsapp">WhatsApp</option>
                      <option value="email">E-mail</option>
                      <option value="appel">Appel</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-4">
            <div class="col-lg-12 col-12">
              <div class="custom-block bg-white">
                <h5 class="mb-4">E-Engagement</h5>

                <div
                  v-if="isLoading"
                  class="spinner-border text-success"
                  role="status"
                >
                  <span class="sr-only">Chargement en cours...</span>
                </div>

                <!-- Tableau des engagements -->
                <div v-if="!isLoading" class="table-responsive">
                  <table class="account-table table">
                    <thead style="background-color: #006666">
                      <tr>
                        <th scope="col" style="color: white">
                          <input
                            type="checkbox"
                            v-model="selectAll"
                            @change="toggleSelectAll"
                          />
                        </th>
                        <th scope="col" style="color: white">N°</th>
                        <th scope="col" style="color: white">CLIENT</th>
                        <th scope="col" style="color: white">E-MAIL</th>
                        <th scope="col" style="color: white">DATE DE RAPPEL</th>
                        <th scope="col" style="color: white">MODE DE RAPPEL</th>
                        <th scope="col" style="color: white">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(engagement, index) in eEngagements"
                        :key="engagement.id"
                      >
                        <td>
                          <input
                            type="checkbox"
                            v-model="selectedClients"
                            :value="engagement"
                          />
                        </td>
                        <td>{{ index + 1 + (currentPage - 1) * perPage }}</td>
                        <td>{{ engagement.full_name }}</td>
                        <td>{{ engagement.email }}</td>
                        <td>
                          {{ JSON.parse(engagement.payment_dates).join(", ") }}
                        </td>
                        <td>
                          {{ JSON.parse(engagement.reminder_modes).join(", ") }}
                        </td>
                        <td>
                          <button class="btn btn-success">Audio</button>
                          <span>&nbsp;</span>
                          <button
                            class="btn btn-danger"
                            @click="sendBulkMessage"
                          >
                            <span v-if="loading[engagement.id]">
                              <i class="fas fa-spinner fa-spin"></i>
                            </span>
                            <span v-else>
                              <i class="fab fa-whatsapp"></i> Message
                            </span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="!eEngagements.length && !isLoading">
                      <tr>
                        <td colspan="6" class="text-center">
                          Aucun engagement trouvé.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <nav aria-label="Page navigation">
            <ul class="pagination">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <a
                  class="page-link"
                  @click.prevent="changePage(currentPage - 1)"
                  >Précédent</a
                >
              </li>
              <li
                v-for="page in lastPage"
                :key="page"
                class="page-item"
                :class="{ active: currentPage === page }"
              >
                <a class="page-link" @click.prevent="changePage(page)">{{
                  page
                }}</a>
              </li>
              <li
                class="page-item"
                :class="{ disabled: currentPage === lastPage }"
              >
                <a
                  class="page-link"
                  @click.prevent="changePage(currentPage + 1)"
                  >Suivant</a
                >
              </li>
            </ul>
          </nav>
        </main>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from "sweetalert2";

export default {
  components: { Header, Footer, SideBar },
  data() {
    const today = new Date().toISOString().split("T")[0];

    return {
      eEngagements: [],
      currentPage: 1,
      lastPage: 1,
      perPage: 15,
      totalItems: 0,
      links: [],
      selectedClients: [],
      selectAll: false,
      loading: {},
      isLoading: false,
      date: today,
      reminderMode: "",
    };
  },
  mounted() {
    this.fetchEEngagements();
  },
  methods: {
    async fetchEEngagements(page = 1) {
      this.isLoading = true;
      const params = {
        page,
        date: this.date,
        reminderMode: this.reminderMode,
      };

      try {
        const response = await axios.get("/e-engagements", { params });
        this.eEngagements = response.data.data;
        this.currentPage = response.data.current_page;
        this.lastPage = response.data.last_page;
        this.totalItems = response.data.total;
        this.links = response.data.links;
      } catch (error) {
        console.error("Error fetching engagements:", error);
      } finally {
        this.isLoading = false;
      }
    },
    changePage(page) {
      if (page && page !== this.currentPage) {
        this.fetchEEngagements(page);
      }
    },
    toggleSelectAll() {
      this.selectedClients = this.selectAll
        ? this.eEngagements.map((engagement) => engagement)
        : [];
    },
    async sendBulkMessage() {
      console.log("sendBulkMessage triggered");
      console.log("Selected clients: ", this.selectedClients);

      if (!this.selectedClients.length) {
        Swal.fire("Erreur", "Aucun client sélectionné.", "warning");
        return;
      }

      this.selectedClients.forEach((client) => {
        this.loading[client.id] = true;
      });

      try {
        const messages = [];

        const promises = this.selectedClients.map(async (client) => {
          const paymentDates = JSON.parse(client.payment_dates);
          const whatsappNumber = client.whatsapp_number;

          const response = await axios.post("/send-whatsapp-message", {
            full_name: client.full_name,
            payment_dates: paymentDates,
            whatsapp_number: whatsappNumber,
          });

          // Stocker le message de succès pour chaque client
          messages.push(`Message whatsapp envoyé`);
        });

        await Promise.all(promises);
        Swal.fire("Succès", messages.join("<br>"), "success");
      } catch (error) {
        const errorMessage = error.response?.data?.errors
          ? Object.values(error.response.data.errors).flat().join(", ")
          : "Une erreur s'est produite lors de l'envoi des messages.";
        Swal.fire("Erreur", errorMessage, "error");
      } finally {
        this.selectedClients.forEach((client) => {
          this.loading[client.id] = false;
        });
      }
    },
  },
};
</script>
